import React from 'react';
import { debounce } from 'lodash';
import { oneOf } from 'prop-types';
import HigherOrderComponent from '@oldscope/hoc';

const getOffsetTop = elem => {
    let offsetTop = 0;
    do {
      if ( !Number.isNaN( elem.offsetTop ) ) {
          offsetTop += elem.offsetTop;
      }
      elem = elem.offsetParent;
    } while(elem);
    return offsetTop;
}

export default class ScrollTrigger extends React.Component {

    static propTypes = {
        trigger: oneOf(['reachInlinePosition', 'belowTop']),
    }

    static defaultProps = {
        trigger: 'reachInlinePosition',
    }

    state = {
        triggered: false,
    };

    componentDidMount = () => {
        this._originalPos = getOffsetTop(this._posFinder);
        this.detectScrollPos();
        window.addEventListener('scroll', this.detectScrollPos);
    };
    
    componentWillUnmount = () => window.removeEventListener('scroll', this.detectScrollPos);

    detectScrollPos = debounce(() => {
        const scrollY = window.scrollY || window.pageYOffset;
        const triggeredTest = {
            reachInlinePosition: scrollY > this._originalPos,
            belowTop: scrollY > 0,
        }[this.props.trigger];
        
        const triggered = !!triggeredTest;
        // eslint-disable-next-line eqeqeq
        if (triggered != this.state.triggered) this.setState({ triggered });
    }, 25, { maxWait: 25 });

    render () {
        const { children } = this.props;
        const { triggered } = this.state;

        return [
            <span key='id' className='scrollTriggerID' ref={c => this._posFinder = c} />,
            ...React.Children.map(children(triggered), ch => ch)
        ];
    }

}

export const ScrollTriggerHOC = HigherOrderComponent((InnerComponent, Opts) => (
    props => (
        <ScrollTrigger {...Opts}>
            {triggered => <InnerComponent {...props} triggered={triggered} />}
        </ScrollTrigger>
    )
));
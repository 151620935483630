import React from 'react'
import PodcastItem from '@oldscope/ui/components/Feed/items/PodcastItem'
import Feed from './_ContentfulFeed'
import { ContextSubscriber } from '../LegacyContext'

const PodcastItemContainer = props => (
  <ContextSubscriber subscriptions={{ globals: ['change'] }}>
    {({ globals: { change } }) => (
      <PodcastItem
        handleItemClick={({ filename }) => change('playerSrc', filename)}
        {...props}
        url={null}
      />
    )}
  </ContextSubscriber>
)

const PodcastFeed = () => (
  <Feed
    ItemComponent={PodcastItemContainer}
    extraFields={['artist', 'filename']}
    contentType="podcastEpisode"
    placeholderIcon="sound"
  />
)

export default PodcastFeed

import React from 'react'
import { JSSSingle } from '@oldscope/jss'
import { oneOf } from 'prop-types'
// import { upperFirst } from 'lodash'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import cx from 'classnames'
import { /* controls, */ withMediaProps } from './custom_modules/react-media-player'

const styles = {
  handle: {
    borderSize: '2px',
    height: '16px',
    width: '16px',
    borderRadius: '8px',
    marginTop: '0px',
    top: '0px',
  },
}

class Range extends React.Component {
  static propTypes = {
    type: oneOf(['volume', 'seekBar']),
  }

  render() {
    const {
      jssClass,
      type,
      media,
      color1,
      color2,
      handleBG,
      className,
    } = this.props
    // const C = controls[upperFirst(type)]

    return (
      <Slider
        className={cx(jssClass, className)}
        min={0}
        step={0.1}
        orientation="horizontal"
        handleStyle={{
          ...styles.handle,
          borderColor: color1,
          background: handleBG,
        }}
        trackStyle={{ height: '2px', backgroundColor: color1 }}
        railStyle={{ height: '2px', backgroundColor: color2 }}
        {...{
          volume: {
            value: media.volume,
            onChange: media.setVolume,
            max: 1,
          },
          seekBar: {
            value: media.currentTime,
            onChange: media.seekTo,
            max: media.duration,
          },
        }[type]}
      />
    )
  }
}

export default withMediaProps(
  JSSSingle({
    position: 'relative',
    height: '16px',
    padding: '0px !important',
    margin: '0px !important',
    width: '100%',
    borderRadius: '6px',
    touchAction: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',

    '& *': {
      boxSizing: 'border-box',
      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    },
  })(Range),
)

import React from 'react'
import styled from 'styled-components'
import NavItem from '../../components/Nav/NavItem'

const MyNavItem = styled(NavItem)`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 20px;

  &:before {
    width: 1px;
    height: 25%;
    background-color: #ffc864;
  }

  &:first-child:before {
    background: none;
    width: 0px;
    height: 0px;
  }

  @media (max-width: 999px) {
    font-size: 16px;
  }

  @media (max-width: 799px) {
    font-size: 20px;
  }
`
export default ({ classes, className, ...props }) => (
  <MyNavItem className={className} {...props} spacing="large" />
)

const keyList = Object.keys;
const hasProp = Object.prototype.hasOwnProperty;

const equal = (a, b, { keyExclude, keyPattern }={}) => {
	if (a === b) return true;

	const arrA = Array.isArray(a);
	const arrB = Array.isArray(b);
	let i;
	let length;
	let key;

	if (arrA && arrB) {
		length = a.length;
		if (length !== b.length) return false;
		for (i = 0; i < length; i++)
			if (!equal(a[i], b[i])) return false;
		return true;
	}

	if (arrA !== arrB) return false;

	const dateA = a instanceof Date;
	const dateB = b instanceof Date;
	if (dateA !== dateB) return false;
	if (dateA && dateB) return a.getTime() === b.getTime();

	const regexpA = a instanceof RegExp;
	const regexpB = b instanceof RegExp;
	if (regexpA !== regexpB) return false;
	if (regexpA && regexpB) return a.toString() === b.toString();

	if (a instanceof Object && b instanceof Object) {
		const keys = keyList(a);
		length = keys.length;

		if (length !== keyList(b).length)
			return false;

		for (i = 0; i < length; i++)
			if (!hasProp.call(b, keys[i])) return false;

		for (i = 0; i < length; i++) {
			key = keys[i];
    		// eslint-disable-next-line no-continue
			if (keyExclude && (keyExclude === key)) continue;
    		// eslint-disable-next-line no-continue
			else if (keyPattern && !key.match(keyPattern)) continue;
			if (!equal(a[key], b[key])) return false;
		}

		return true;
	}

	return false;
};

export default equal;
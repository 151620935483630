import { format, parse } from 'date-fns'
import { compact } from 'lodash'

// eslint-disable-next-line import/prefer-default-export
export const FeedDate = ({ date, dates: _dates = [] }) => {
  const dates = compact(date ? [date] : _dates).map(
    d => d && parse(d.substr(0, 10), 'yyyy-MM-dd', new Date()),
  )
  const omitSecondMonth =
    dates.length === 2 &&
    format(dates[0], 'yyyy-MM') === format(dates[1], 'yyyy-MM')

  if (dates.length < 1) return 'No date set'

  return (
    `${dates
      .map((d, i) => format(d, omitSecondMonth && i === 1 ? 'd' : 'MMM d'))
      .join(' - ') 
    }, ${ 
    format(dates[0] || dates[1], 'yyyy')}`
  )
}

import { create as createJss } from './custom_modules/jss';
import JSSComponentPrefixer from './JSSComponentPrefixer';
import preset from './custom_modules/jss-preset-default';

export default (name) => {
	const myPreset = preset();
	// myPreset.insertionPoint = `jss_${name}`;

	const jss = createJss(myPreset);
	jss.use(JSSComponentPrefixer(name));

	jss.attachStaticSheet = (prefix, css) => jss.createStyleSheet(css, { meta: `${prefix}Static` }).attach();
	return jss; 
};
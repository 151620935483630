import React from 'react'
import './global.css'
// import SocialOpts from './SocialOpts';
import Route from 'react-router-dom/Route'
import Redirect from 'react-router-dom/Redirect'
import Switch from 'react-router-dom/Switch'
import withRouter from '@oldscope/utils/withRouter'
import asyncComponent from '@oldscope/utils/asyncComponent'
import { isPlainObject } from 'lodash'
import querystring from 'querystring'
import { Provider as AuthTokenProvider } from '@covenant-presbyterian/registration/lib/contexts/AuthTokenContext'
import Hero from './components/Hero'
import { ContextProvider } from './components/LegacyContext'
import Spacing from './components/Spacing'
import TopNav from './nav/top/TopNav'
import PlayerBar from './nav/PlayerBarContainer'
import Hamburger from './nav/Hamburger'
import FeedBar from './nav/FeedBar'

const JSONParse = str => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return {}
  }
}

export const Page = asyncComponent(() => import('./pages/Page'))
const Post = asyncComponent(() => import('./pages/Post'))
const Contact = asyncComponent(() => import('./pages/Contact'))
const Home = asyncComponent(() => import('./pages/Home'))
const Event = asyncComponent(() => import('./pages/Event'))
const Ministries = asyncComponent(() => import('./pages/Ministries'))
const Committees = asyncComponent(() => import('./pages/Committees'))
const Churches = asyncComponent(() => import('./pages/Churches/Churches'))
const GA = asyncComponent(() => import('./pages/ga'))
const Login = asyncComponent(() => import('./pages/Login'))

@withRouter
class Nav extends React.Component {
  state = {
    globals: {
      feedBarVisible: false,
      feedBarTab: null,
      hamburgerVisible: false,
      playerSrc: null,
    },
  }

  changeGlobal = (name, val) => {
    if (isPlainObject(name))
      this.setState(({ globals }) => ({ globals: { ...globals, ...name } }))
    else
      this.setState(({ globals }) => ({ globals: { ...globals, [name]: val } }))
  }

  render() {
    const { isHome } = this.props
    return (
      <ContextProvider
        name="globals"
        context={{
          ...this.state.globals,
          change: this.changeGlobal,
        }}
      >
        {isHome() && <Hero />}
        <TopNav handleSwitchPage={this.switchPage} />
        <FeedBar />
        <Hamburger />
        <PlayerBar />
        {/* <SocialOpts /> */}
      </ContextProvider>
    )
  }
}

@withRouter
class App extends React.Component {
  constructor(props) {
    super(props)
    const { access_token, id_token } = this.getQuery(props)

    const authTokens = id_token
      ? { access_token, id_token }
      : JSONParse(window.sessionStorage.getItem('authTokens'))

    window.sessionStorage.setItem('authTokens', JSON.stringify(authTokens))

    this.state = { authTokens }
  }

  getQuery = (props = this.props) => ({
    ...querystring.parse((props.location.search || '').replace('?', '')),
    ...querystring.parse((props.location.hash || '').replace('#', '')),
  })

  render() {
    const {
      // access_token,
      id_token,
      // expires_in,
      // token_type,
      ...other
    } = this.getQuery()

    return id_token ? (
      <Redirect
        to={{
          pathname: this.props.location.pathname,
          search: querystring.stringify(other),
        }}
      />
    ) : (
      <AuthTokenProvider value={this.state.authTokens}>
        <Nav key="nav" />
        <Switch key="switch">
          <Route path="/login" component={Login} />
          <Route path="/churches" component={Churches} />
          <Route path="/post/:slug" component={Post} />
          <Route path="/event/:slug" component={Event} />
          <Route path="/contact" component={Contact} />
          <Route path="/ministries" component={Ministries} />
          <Route path="/committees" component={Committees} />
          <Route path="/" exact component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/ga" component={GA} />
          <Route path="/:slug" component={Page} />
        </Switch>
        <Spacing size="xlarge" key="appspacing" />
      </AuthTokenProvider>
    )
  }
}

export default App

import React from 'react'
import styled from 'styled-components'
import { spacing as spacingOpts } from '@oldscope/ui/_options'
import { pick } from 'lodash'
import { any, string, oneOf, arrayOf, oneOfType, bool } from 'prop-types'
import { Icon } from 'react-fa'
import { Popup } from 'semantic-ui-react'
import Link from '../Link'
import 'semantic-ui-css/components/popup.css'

const Item = styled.li`
  position: relative;
  display: ${({ orientation }) =>
    orientation === 'vertical' ? 'flex' : 'inline-flex'};
  align-items: center;
  height: 100%;
  vertical-align: top;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(
      ${({ orientation }) =>
        orientation === 'vertical' ? '-50%, 0%' : '0%, -50%'}
    );
  }

  &:hover > div {
    display: block;
  }
`

const MyLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  ${({ spacing, orientation }) => {
    const getPaddingStr = n => {
      const padding = [`${n / 2}px`]
      padding[orientation === 'horizontal' ? 'unshift' : 'push']('0px')
      return padding.join(' ')
    }

    const standardPadding = parseInt(spacingOpts[spacing].replace('px', ''), 10)

    return `
      padding: ${getPaddingStr(standardPadding)};
      @media (max-width: 999px) {
        padding: ${getPaddingStr(Math.round((standardPadding / 4) * 3))};
      }
      @media (max-width: 799px) {
        padding: ${getPaddingStr(standardPadding)};
      }
    `
  }}
`

const MyIcon = styled(Icon)`
  margin-right: ${spacingOpts.tiny};
`

class NavItem extends React.Component {
  static propTypes = {
    icon: oneOfType([string, arrayOf(string)]),
    label: any,
    children: any,
    orientation: oneOf(['horizontal', 'vertical']),
    href: string,
    noLink: bool,
  }

  static defaultProps = {
    // I don't really understand this error, and I'd guess it is incorrect code, but I'm not sure the right way to fix it.
    // eslint-disable-next-line react/default-props-match-prop-types
    spacing: 'mediumLarge',
    orientation: 'horizontal',
    href: '#',
  }

  render() {
    const {
      // classes,
      icon,
      label,
      children,
      className,
      iconClassName,
      linkClassName,
      hideLabel,
      style,
      noLink,
      orientation,
      spacing,
    } = this.props
    // if (!Array.isArray(icon)) icon = [ icon ];

    // let renderedIcon = icon.map((ic, i) => <Icon key={ic} name={ic} className={classes.icon} style={i > 0 ? { marginLeft: '7px' } : null} />);
    let renderedIcon = icon ? (
      <MyIcon name={icon} className={iconClassName} />
    ) : null
    if (icon && hideLabel)
      renderedIcon = (
        <Popup
          position="bottom center"
          trigger={renderedIcon}
          content={label}
        />
      )

    return (
      <Item className={className} style={style} orientation={orientation}>
        <MyLink
          {...{ spacing, orientation }}
          as={noLink ? 'div' : Link}
          className={linkClassName}
          {...(!noLink && pick(this.props, ['onClick', 'href']))}
        >
          {renderedIcon}
          {hideLabel ? '' : label}
        </MyLink>
        {children}
      </Item>
    )
  }
}

export default NavItem

import React, { Component } from 'react'
import JSS from '@oldscope/jss'
import {
  Media,
  Player,
} from './custom_modules/react-media-player'
import PlayPause from './PlayPause'
import MuteUnmute from './MuteUnmute'
import 'semantic-ui-css/components/icon.css'
import './main.css'
import Range from './Range'
import TimeStamp from './TimeStamp'

class AudioPlayer extends Component {
  static defaultProps = {
    color1: '#000',
    color2: '#999',
  }

  close = e => {
    e.preventDefault()
    if (this.props.handleClose) this.props.handleClose()
  }

  render() {
    const { classes, color1, color2, handleBG } = this.props

    return (
      <Media>
        <div className={classes.container}>
          <Player
            ref={c => (this._player = c)}
            src={this.props.src}
            useAudioObject
            autoPlay
            vendor="audio"
          />
          <PlayPause color={color1} className={classes.playPause} />
          <div className={classes.ranges}>
            <Range type="seekBar" {...{ color1, color2, handleBG }} />
            <div className={classes.volumeAndTS}>
              <div className={classes.timestamps}>
                <TimeStamp type="currentTime" /> / <TimeStamp type="duration" />
              </div>
              <MuteUnmute className={classes.muteUnmute} />
              <Range
                type="volume"
                {...{ color1, color2, handleBG }}
                className={classes.volume}
              />
            </div>
          </div>
        </div>
      </Media>
    )
  }
}

export default JSS({
  container: ({ _jss, color1 }) => ({
    width: '100%',
    display: 'flex',
    color: color1,
    alignItems: 'center',
  }),

  playPause: {
    flex: '0 0 auto',
  },

  ranges: {
    flex: '1 1 auto',
    paddingLeft: '15px',
  },

  timestamps: {
    fontSize: '15px',
    marginRight: '20px',
    whiteSpace: 'nowrap',
  },

  volumeAndTS: {
    marginTop: '15px',
    display: 'flex',
  },

  muteUnmute: {
    marginRight: '6px',
  },

  volume: {
    flex: '1px 1px auto',
  },
})(AudioPlayer)

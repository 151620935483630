import React from 'react'
import styled from 'styled-components'
import NavItem from '../../components/Nav/NavItem'
import colors from '../../variables/colors'

const MyNavItem = styled(NavItem)`
  font-family: 'Averia Serif Libre', sans-serif !important;
  font-size: 20px !important;
  padding: 0px !important;
`

const MyNavItemWithLink = styled(({ className, ...other }) => (
  <MyNavItem linkClassName={className} {...other} />
))`
  color: ${colors.primary};
  font-size: 16px;
  padding: 10px 20px;
  display: block;
  width: 100%;

  &:hover {
    background-color: ${colors.primary};
    color: #fff;
  }
`

export default props => <MyNavItemWithLink {...props} spacing="large" />

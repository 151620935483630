import React, { createContext, useContext } from 'react'
import DataProvider from '@oldscope/dataprovider'
import { cloneDeep } from 'lodash'

const ContentfulContext = createContext()

export const ContentfulQuery = ({
  client: clientProp,
  debugLoader,
  query,
  children,
  loaderType,
  invertLoader,
  single,
  afterFetch,
}) => {
  const client = useContext(ContentfulContext) || clientProp

  const fetcher = queryProps =>
    client.getEntries(cloneDeep(queryProps)).then((data = {}) => {
      let toReturn
      if (data.fields) toReturn = data.fields
      else if (data.items) {
        if (single) toReturn = data.items[0] && data.items[0].fields
        else toReturn = data.items
      } else toReturn = data

      if (afterFetch) afterFetch(toReturn)
      return toReturn
    })

  return (
    <DataProvider
      invertLoader={invertLoader}
      loaderType={loaderType}
      debugLoader={debugLoader}
      name="contentful"
      fetcher={fetcher}
      queryProps={query}
    >
      {children}
    </DataProvider>
  )
}

export const ContentfulProvider = ({ client, children }) => (
  <ContentfulContext.Provider value={client}>
    {children}
  </ContentfulContext.Provider>
)

import React from 'react'
import styled from 'styled-components'
import Link from './Link'

const MyLink = styled(Link)`
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
`

const Line = styled.div`
  position: absolute;
  top: -2px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 20px;
`

const Line1 = styled(Line)`
  transform: rotate(45deg);
`

const Line2 = styled(Line)`
  transform: rotate(-45deg);
`

const CloseButton = ({ onClick, className, background = '#888' }) => (
  <MyLink onClick={onClick} className={className}>
    <IconContainer>
      <Line1 style={{ background }} />
      <Line2 style={{ background }} />
    </IconContainer>
  </MyLink>
)

export default CloseButton

import React from 'react'
import JSS from '@oldscope/jss'
import { capitalize } from 'lodash'
import { opposite } from '@oldscope/ui/_cssUtilities'

const makeFloatComponent = float => {
  class Float extends React.Component {
    render() {
      const { classes, children } = this.props
      return <div className={classes.container}>{children}</div>
    }
  }

  return JSS({
    container: {
      float,
      [`margin${capitalize(opposite(float))}`]: 'auto',
    },
  })(Float)
}

export const Left = makeFloatComponent('left')
export const Right = makeFloatComponent('right')

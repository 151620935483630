import React from 'react'
import JSS from '@oldscope/jss'
import { Image, Icon, Menu } from 'semantic-ui-react'
import 'semantic-ui-css/components/image.css'
import 'semantic-ui-css/components/icon.css'
import 'semantic-ui-css/components/header.css'
import 'semantic-ui-css/components/menu.css'
import { truncate } from 'lodash'
import { string, object, bool } from 'prop-types'
import NavLink from 'react-router-dom/NavLink'
import { domainIsHome } from '@oldscope/utils/url'

class FeedItem extends React.Component {
  static propTypes = {
    showImg: bool,
    image: string,
    classes: object,
    placeholderIcon: string,
    title: string,
  }

  static defaultProps = {
    showImg: true,
  }

  onItemClick = slug => {
    if (this.props.handleItemClick) this.props.handleItemClick(this.props)
  }

  render() {
    const {
      showImg,
      image,
      classes,
      placeholderIcon,
      title,
      children,
      url,
      // slug,
    } = this.props

    return (
      <Menu.Item
        {...(url && domainIsHome(url)
          ? { as: NavLink, to: url }
          : { as: 'a', ...(url && { href: url, target: '_blank' }) })}
        onClick={this.onItemClick}
        className={classes.item}
      >
        {showImg &&
          (image ? (
            <Image
              className={classes.image}
              src={`${image}?fit=thumb&w=50&h=50&r=5&bg=rgb:1B1C1D`}
            />
          ) : (
            <div className={classes.placeholderIconContainer}>
              <Icon
                className={classes.placeholderIcon}
                size="big"
                name={placeholderIcon}
              />
            </div>
          ))}
        <div className={classes.container}>
          <Menu.Header as="h4" className={classes.title}>
            {truncate(title, { length: 50 })}
          </Menu.Header>
          {children}
        </div>
      </Menu.Item>
    )
  }
}

export default JSS({
  image: {
    flex: '0 1 auto !important',
    verticalAlign: 'middle !important',
    display: 'inline-block !important',
    borderRadius: '5px !important',
  },
  placeholderIcon: {
    verticalAlign: 'middle !important',
    position: 'relative',
    margin: '0px !important',
    float: 'none !important',
    display: 'inline-block !important',
  },
  placeholderIconContainer: {
    border: `2px solid #ccc`,
    flex: '0 1 auto',
    width: '50px',
    minWidth: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
  },
  container: {
    flex: '1 1 auto !important',
    fontSize: '.82em',
    margin: '0px 0px 0px 13px',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  title: {
    // paddingTop: '6px !important',
    margin: '0px 0px 6px 0px !important',
    fontFamily: 'inherit !important',
  },
  item: {
    display: 'flex !important',
    alignItems: 'center !important',
  },
})(FeedItem)

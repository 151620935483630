import React from 'react'
import Transition from 'react-transition-group/Transition'
import JSS from '@oldscope/jss'
import cx from 'classnames'
import Link from '../../components/Link'

const duration = 300
const imgUrl =
  'https://images.contentful.com/4ecu34rp31r9/4vkYZDkjyEYSgCgeOss0qS/88b052d679b4cc06c10a15922a173e6c/cpc-logo_white.png'

@JSS({
  container: {
    float: 'left',
    transition: `margin-right ${duration}ms ease-in-out 0s, width ${duration}ms ease-in-out 0s, opacity ${duration}ms ease-in-out 100ms`,
    height: '50px',
    overflow: 'visible',
    opacity: 0,
    width: '0px',
    marginRight: '0px',

    '@media (max-width: 999px)': {
      height: '45px',
    },
  },
  entering: {
    opacity: 0,
    width: '0px',
  },
  entered: {
    opacity: 1,
    width: '60px',
    marginRight: '13px',

    '@media (max-width: 999px)': {
      marginRight: '5px',
    },
  },
  img: {
    width: '60px',
    height: '50px',
    background: `url("${imgUrl}") 0px 0px`,
    backgroundSize: '164px 50px',

    '@media (max-width: 999px)': {
      width: '54px',
      height: '45px',
      backgroundSize: '148px 45px',
    },
  },
})
class Logo extends React.Component {
  render() {
    const { in: inProp, classes } = this.props

    return (
      <Transition in={inProp} timeout={duration}>
        {state => (
          <Link href="/" className={cx(classes.container, classes[state])}>
            <div className={classes.img} />
          </Link>
        )}
      </Transition>
    )
  }
}

export default Logo

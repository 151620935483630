import React from 'react'
import JSS from '@oldscope/jss'
import { pick } from 'lodash'
import cx from 'classnames'

class PlayerButton extends React.Component {
  render() {
    const { className, classes } = this.props

    return (
      <button
        {...pick(this.props, ['onClick', 'style', 'children', 'onMouseOver'])}
        className={cx(className, classes.btn)}
      />
    )
  }
}

export default JSS({
  btn: {
    width: '60px',
    height: '60px',
    borderRadius: '30px',
    borderWidth: '2px',
    borderStyle: 'solid',
    cursor: 'pointer',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})(PlayerButton)

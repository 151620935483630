import React from 'react'
import { LoaderOverlay } from '@oldscope/ui'
import { object, func, string } from 'prop-types'
import { isEqual, isFunction } from 'lodash'
import ErrorBoundary from '@oldscope/error-handling/ErrorBoundaryHOC'
import { Loader } from 'semantic-ui-react'
import 'semantic-ui-css/components/loader.css'

class DataProvider extends React.Component {
  state = {
    fetching: false,
    data: null,
    err: null,
  }

  static propTypes = {
    queryProps: object,
    fetcher: func,
    name: string,
  }

  static defaultProps = {
    queryProps: {},
    fetcher: callback => {
      callback()
    },
    name: 'provider',
  }

  UNSAFE_componentWillMount = () => this.fetch(this.props.queryProps)

  UNSAFE_componentWillReceiveProps = nextProps =>
    !isEqual(nextProps.queryProps, this.props.queryProps) &&
    this.fetch(nextProps.queryProps)

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.err && this.state.err) {
      throw new Error(this.state.err)
    }
  }

  fetch = queryProps => {
    this.setState({ fetching: true })
    this.props
      .fetcher(queryProps)
      .then(data =>
        this.props.debugLoader
          ? ''
          : this.setState({
              data: data || '',
              fetching: false,
            }),
      )
      .catch(err =>
        this.setState({
          err,
          fetching: false,
        }),
      )
  }

  render() {
    const { data, fetching } = this.state
    // eslint-disable-next-line no-unused-vars
    const { name, children, invertLoader, loaderType } = this.props

    if (fetching)
      return loaderType === 'inline' ? (
        <Loader active inverted={invertLoader} />
      ) : (
        <LoaderOverlay invertLoader={invertLoader} />
      )
    if (!isFunction(children)) {
      throw new Error('Children of this component must be a function')
    } else return children(data)
  }
}

export default ErrorBoundary(DataProvider)

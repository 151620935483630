import { each, assign, map, values } from 'lodash'

export const clickable = { cursor: 'pointer' }
export const draggable = { cursor: 'move' }
export const hidden = { display: 'none' }

export const clearfix = {
  '&:after': {
    content: '""',
    display: 'table',
    clear: 'both',
  },
  ':after': {
    content: '""',
    display: 'table',
    clear: 'both',
  },
}

export const hand = {
  cursor: 'pointer',
  userSelect: 'none',
}

export const absoluteFill = {
  position: 'absolute',
  left: '0px',
  top: '0px',
  width: '100%',
  height: '100%',
}

export const parseAlignString = align => {
  align = Array.isArray(align) ? align : [align]
  const params = {}

  each(align, function(alignParam, i) {
    // eslint-disable-next-line eqeqeq
    if (i == 1 || alignParam.indexOf('v-') > -1) {
      params.v = alignParam.replace('v-', '')
    } else {
      params.h = alignParam.replace('h-', '')
    }
  })

  return params
}

export const alignment = (align, noTop) => {
  const alignParams = this.parseAlignString(align)
  const transformTranslate = ['0%', '0%']
  const css = { position: 'relative' }

  if (alignParams.v)
    switch (alignParams.v) {
      case 'normal':
        break
      case 'top':
        css.top = '0px'
        break
      case 'middle':
        assign(css, noTop || { top: '50%' })
        transformTranslate[1] = '-50%'
        break
      case 'bottom':
        assign(css, noTop || { top: '100%' })
        transformTranslate[1] = '-100%'
        break
      default:
        break
    }

  if (alignParams.h)
    switch (alignParams.h) {
      case 'normal':
        break
      case 'left':
        css.float = 'left'
        break
      case 'center':
        assign(css, { left: '50%' })
        transformTranslate[0] = '-50%'
        break
      case 'right':
        css.float = 'right'
        break
      default:
        break
    }

  css.transform = `translate(${  transformTranslate.join(',')  })`
  return css
}

export const transitionStr = (propList, action) =>
  map(propList, function(p) {
    return `${p} ${action}`
  }).join(', ')

export const opposite = propName =>
  ({
    left: 'right',
    right: 'left',
    top: 'bottom',
    bottom: 'top',
    vertical: 'horizontal',
    horizontal: 'vertical',
  }[propName])

export const otherInOrder = propName =>
  ({
    top: 'left',
    bottom: 'right',
    left: 'top',
    right: 'bottom',
  }[propName])

export const getPosProp = (orientation, pos) => {
  const c = {
    vertical: { first: 'top', last: 'bottom' },
    horizontal: { first: 'left', last: 'right' },
  }

  if (pos) return c[orientation][pos]
  return values(c[orientation])
}

export const getOrientation = prop => {
  return {
    left: 'horizontal',
    right: 'horizontal',
    top: 'vertical',
    bottom: 'vertical',
  }[prop]
}

export const getSpanProp = orientation =>
  ({
    vertical: 'height',
    horizontal: 'width',
  }[orientation])

export const linkResets = {
  ':visited, :focus, :hover, :active': {
    textDecoration: 'none',
    outline: 'none',
    outlineStyle: 'none',
  },
}

export const absoluteCenter = _which => {
  const which = _which === 'both' ? { h: true, v: true } : { [_which]: true }

  const getTranslate = shouldTranslate => (shouldTranslate ? '-50%' : '0%')

  return `
		position: absolute;
		${which.v && `top: 50%;`}
		${which.h && `left: 50%;`}
		transform: translate(${getTranslate(which.h)}, ${getTranslate(which.v)});
	`
}

// import PerfTesting from 'utils/PerfTesting';
import React from 'react'
import {
  set,
  each,
  keys,
  get,
  map,
  isEqual,
  isString,
  assign,
  assignWith,
  isUndefined,
} from 'lodash'
import HigherOrderComponent from '@oldscope/hoc'
import { obj_compact , pickFromObjs } from '@oldscope/utils/obj'
import cx from 'classnames'
import JSSCachedSheets from './JSSCachedSheets'

import { splitCSSTypes } from './util'

const JSSHOC = HigherOrderComponent((InnerComponent, Opts) => {
  // PerfTesting.start('redundant.init');

  let { modID, _single, ...css } = Opts

  const componentName = modID || InnerComponent.displayName || InnerComponent.name
  css = splitCSSTypes(css)

  const sheets = new JSSCachedSheets(componentName)

  class JSS extends React.PureComponent {
    static defaultProps = InnerComponent.defaultProps

    static componentName = componentName

    state = {}

    sheetHandles = {}

    releaseSheetHandles = () =>
      each(keys(this.sheetHandles), this.releaseSheetHandle)

    releaseSheetHandle = name => {
      // PerfTesting.start('lifecycle.releaseHandle');
      const handle = this.sheetHandles[name]
      if (handle) {
        sheets.releaseHandle(handle.argStr, handle.subscriberID)
        this.sheetHandles[name] = null
      } // PerfTesting.stop('lifecycle.releaseHandle');
    }

    getDynamicSheetHandle(props) {
      // PerfTesting.start('lifecycle.getDynamicSheet');
      if (!props) props = this.props
      if (!css.dynamic) return
      // PerfTesting.start('pick');
      const argStr = JSON.stringify(pickFromObjs(props, css.allArgs))
      const staticClasses = get(this, 'sheetHandles.static.sheet.classes')

      if (
        !this.sheetHandles.dynamic ||
        // eslint-disable-next-line eqeqeq
        !(this.sheetHandles.dynamic.argStr == argStr)
      ) {
        this.releaseSheetHandle('dynamic') // PerfTesting.start('redundant.getHandle');
        this.sheetHandles.dynamic = sheets.getHandle(
          argStr,
          () => {
            const dynCSS = {}

            each(
              css.dynamic,
              (obj, idx) => {
                const ruleArgs = pickFromObjs(
                  [this.state, props, InnerComponent.defaultProps || {}],
                  obj.args,
                  'obj',
                  true,
                )
                const result = obj.fn.apply(this, [ruleArgs]) || {}
                const {composes} = result
                if (staticClasses && composes) {
                  const replaceComposes = c => {
                    const staticClass = staticClasses[c.replace('$', '')]
                    return c.charAt(0) === '$' && staticClass ? staticClass : c
                  }
                  if (Array.isArray(composes))
                    result.composes = map(composes, replaceComposes)
                  else result.composes = replaceComposes(composes)
                }
                set(dynCSS, idx, obj_compact(result))
              },
              { function: true },
            )

            return dynCSS
          },
          'Dynamic',
        ) // PerfTesting.stop('redundant.getHandle');
      } // PerfTesting.stop('lifecycle.getDynamicSheet');
    }

    UNSAFE_componentWillMount = () => {
      if (css.static)
        this.sheetHandles.static = sheets.getHandle(
          'static',
          css.static,
          'Static',
        ) // PerfTesting.stop('lifecycle.getStatic');
      this.getDynamicSheetHandle() // PerfTesting.start('lifecycle.getStatic');
      this.classes = this.getClasses()
    }

    UNSAFE_componentWillUpdate = nextProps => {
      if (!css.dynamic) return

      let isEqualShallow = true
      each(nextProps, (v, i) => {
        if (!(i === 'children') && !(v === this.props[i])) isEqualShallow = false
      })

      if (!isEqualShallow) {
        const updated = !isEqual(
          pickFromObjs(nextProps, css.allArgs),
          pickFromObjs(this.props, css.allArgs),
        )
        if (updated) {
          this.getDynamicSheetHandle(nextProps)
          this.classes = this.getClasses()
        }
      }
    }

    componentWillUnmount = () => this.releaseSheetHandles()

    setCSSProp = (propsOrKey, val) => {
      if (isString(propsOrKey)) this.setState({ [propsOrKey]: val })
      else this.setState(propsOrKey)
    }

    getClasses() {
      // PerfTesting.start('lifecycle.getClasses');
      const classes = {}
      assign(classes, get(this, 'sheetHandles.static.sheet.classes'))
      assignWith(
        classes,
        get(this, 'sheetHandles.dynamic.sheet.classes'),
        (objVal, srcVal) =>
          isUndefined(objVal) ? srcVal : `${objVal} ${srcVal}`,
      ) // PerfTesting.stop('lifecycle.getClasses');
      return classes
    }

    cx = (...args) => cx(args.map(arg => arg && this.classes[arg]))

    render() {
      return (
        <InnerComponent
          {...this.props}
          ref={c => (this._inner = c)}
          {...(_single
            ? { jssClass: this.classes._ }
            : { classes: this.classes })}
          setCSSProp={this.setCSSProp}
          cx={this.cx}
          {...this.state}
        />
      )
    }
  } // PerfTesting.stop('redundant.init');

  return JSS
})

export const JSSSingle = css => JSSHOC({ _: css, _single: true })

export default JSSHOC

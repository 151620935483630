import React from 'react'
import JSS from '@oldscope/jss'
import { any, string, object, oneOf, array } from 'prop-types'
import { getSpanProp, opposite } from '@oldscope/ui/_cssUtilities'
import cx from 'classnames'
import { compact } from 'lodash'
import NavItem from './NavItem'
import SubList from './SubList'

const navSizes = {
  horizontal: {
    large: '100px',
    medium: '60px',
    small: '41px',
  },
  vertical: {},
}

class NavList extends React.Component {
  static propTypes = {
    className: string,
    itemProps: object,
    size: oneOf(['none', ...Object.keys(navSizes.horizontal)]),
    orientation: oneOf(['horizontal', 'vertical']),
    items: array,
    ItemComponent: any,
    SubListComponent: any,
  }

  static defaultProps = {
    size: 'medium',
    orientation: 'horizontal',
    items: [],
    ItemComponent: NavItem,
    SubListComponent: SubList,
  }

  render() {
    const {
      classes,
      children,
      className,
      orientation,
      size,
      items,
      ItemComponent,
      SubListComponent,
    } = this.props
    const sizes = navSizes[orientation]

    return (
      <ul
        style={size && { [getSpanProp(opposite(orientation))]: sizes[size] }}
        className={cx(classes.list, className)}
      >
        {items.map((item, i) => (
          <ItemComponent
            key={item.label || i}
            {...item}
            orientation={orientation}
          >
            {item.subItems && (
              <SubListComponent
                items={item.subItems}
                orientation={opposite(orientation)}
                {...{ ItemComponent, SubListComponent }}
              />
            )}
          </ItemComponent>
        ))}
        {React.Children.map(compact(children), ch =>
          React.cloneElement(ch, { orientation }),
        )}
      </ul>
    )
  }
}

export default JSS({
  list: {
    margin: 0,
    padding: 0,
  },
})(NavList)

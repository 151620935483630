import {
  isObject,
  mapValues,
  flatten,
} from 'lodash'

export const obj_mapValuesDeep = (
  v,
  callback,
  opts = {},
  idx = null,
  i = null,
) => {
  if (opts[typeof v] === true) return callback(v, idx, i)
  if (opts[typeof v]) return opts[typeof v](v, idx)
  if (isObject(v))
    if (opts.returnableObjectFn && opts.returnableObjectFn(v))
      return callback(v, idx, i)
    else
      return mapValues(v, (k, j) =>
        obj_mapValuesDeep(k, callback, opts, idx ? `${idx}.${j}` : j, j),
      )
  return callback(v, idx, i)
}

export const obj_compact = obj => {
  // eslint-disable-next-line no-restricted-syntax
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

const getFromObjs = (objs, path) => {
  let found
  if (Array.isArray(objs)) {
    objs.some(obj => {
      if (obj[path]) {
        found = obj[path]
        return true
      }
      return false
    })
  } else {
    found = objs[path]
  }
  return found
}

export const pickFromObjs = (objs, paths, returnFormat, includeNull) => {
  const newObj = returnFormat === 'array' ? [] : {}
  flatten(paths).forEach(path => {
    let val
    if (path.charAt(0) === 'U' && path.charAt(1) === '_') {
      const parts = path.split('_')
      const shortPath = parts.slice(2).join('_')

      if (parts[1] === 'LENGTH') {
        val = getFromObjs(objs, shortPath).length
      }
    } else val = getFromObjs(objs, path)

    if (includeNull || !(val == null)) {
      if (returnFormat === 'array') newObj.push(val)
      else newObj[path] = val
    }
  })
  return newObj
}

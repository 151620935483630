import React from 'react'
import styled from 'styled-components'
import TopNavList from './_TopNavList'
import TopNavSubItem from './_TopNavSubItem'

const Container = styled.div`
  display: none;
  position: absolute;
  top: 80%;
  left: 0px;
  padding: 5px 0;
  background: #ffffff;
  border-bottom: 3px solid #ffc864;
  border-radius: 5px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.35);
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 30px;
    width: 0;
    height: 0;
    margin-left: 6px;
    border: solid transparent;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 6px;
  }
`

export default ({ items, orientation }) => (
  <Container>
    <TopNavList {...{ items, orientation }} ItemComponent={TopNavSubItem} />
  </Container>
)

import React from 'react'
import StandardPostUI from '../ContentfulDataProvider/StandardPostUI'

export default class Feed extends React.Component {
  render() {
    return (
      <StandardPostUI
        {...this.props}
        loaderType="inline" /* invertLoader={true} */
      />
    )
  }
}

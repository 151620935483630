import React from 'react'
import BaseFeedItem from './BaseFeedItem'
import { FeedDate } from './_parts'

const EventItem = ({
  date: startDateTime,
  endDateTime,
  location,
  url,
  useExternalUrlAsNavLink,
  externalURL,
  ...props
}) => {
  return (
    <BaseFeedItem
      {...props}
      url={(useExternalUrlAsNavLink && externalURL) || url}
    >
      <FeedDate dates={[startDateTime, endDateTime]} /> /{' '}
      {location || 'No location set'}
    </BaseFeedItem>
  )
}

export default EventItem

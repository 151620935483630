import React from 'react'
import { ScrollTriggerHOC } from '@oldscope/ui/components/ScrollTrigger'
import DOMDisplacementIf from '@oldscope/ui/components/DOMDisplacementIf'
import withRouter from '@oldscope/utils/withRouter'
import { isFunction } from 'lodash'
import styled from 'styled-components'

const Bar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 28px 0px 28px;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1001;

  ${({ isHome }) => isHome && `transition: background 0.4s;`}

  ${({ fixed }) =>
    fixed
      ? `
    position: fixed;
    background: #4b4178;
  `
      : `
    position: absolute;
    background: linear-gradient(to bottom, rgba(75,65,120,1) 0%,rgba(75,65,120,0) 100%);
  `}

  @media (max-width: 999px) {
    padding: 0px 15px 0px 15px;
  }

  @media (max-width: 799px) {
    padding: 0px 0px 0px 10px;
  }
`

@withRouter
@ScrollTriggerHOC({
  trigger: 'belowTop',
})
class TopBar extends React.Component {
  render() {
    const { children, className, triggered, isHome } = this.props
    const fixed = triggered || !isHome()

    return (
      <DOMDisplacementIf is={!isHome()}>
        <Bar isHome={isHome()} fixed={fixed} className={className}>
          {isFunction(children) ? children(fixed) : children}
        </Bar>
      </DOMDisplacementIf>
    )
  }
}

export default TopBar

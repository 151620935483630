import { obj_mapValuesDeep } from '@oldscope/utils/obj'
import { set, uniq, isFunction } from 'lodash'
import { fn_extractArgs } from '@oldscope/utils/fn'

export const splitCSSTypes = css => {
  const types = { allArgs: [] }

  obj_mapValuesDeep(
    css,
    (v, idx, ...other) => {
      const type = isFunction(v) ? 'dynamic' : 'static'
      if (type === 'dynamic') {
        const args = fn_extractArgs(v)
        set(types, [type, idx], { fn: v, args })

        types.allArgs = types.allArgs.concat(args)
      } else {
        set(types, `${type}.${idx}`, v)
      }
    },
    {
      function: true,
      returnableObjectFn: v => v && v.args && v.fn,
    },
  )

  if (types.allArgs) {
    types.allArgs = uniq(types.allArgs)
    types.allArgs.sort()
  }

  return types
}

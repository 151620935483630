import { map, trim } from 'lodash'

export const fn_extractArgs = fn => {
  const match =
    fn.toString().match(/function\s?.*?\(([^)]*)\)/) ||
    fn.toString().match(/\(([^)]*)\)\s=>\s/)

  const objMatch = fn.toString().match(/\({([^)]*)}\)\s=>\s/)
  const args = map((objMatch || match)[1].split(','), str =>
    trim(str, [' ', '_', '\n']),
  )
  return objMatch ? [args] : args
}

export const fn_pipe = functions => data =>
  functions.reduce((value, func) => func(value), data)

import React from 'react'
import { Table } from 'semantic-ui-react'
import 'semantic-ui-css/components/table.css'
import 'semantic-ui-css/components/button.css'
import Header from '@oldscope/ui/components/Header'
import { get, map } from 'lodash'
import Parser from 'commonmark/lib/blocks'
import ReactRenderer from 'commonmark-react-renderer'
import JSS from '@oldscope/jss'
import ReactHtmlParser from './custom_modules/react-html-parser'
import Link from '../Link'

class MyTableBase extends React.Component {
  render() {
    return <Table {...this.props} className={this.props.classes.table} />
  }
}

const MyTable = JSS({
  table: {
    '& p:first-child': { marginTop: '0px' },
    '& p:last-child': { marginBottom: '0px' },
  },
})(MyTableBase)

const getRenderers = extraRenderers => {
  const transform = (node, key) => {
    if (!node) return null
    let { type, name, data, attribs, children } = node

    if (children)
      children = children.map(ch => ({
        ...ch,
        name: ch.type === 'text' ? name : ch.name,
      }))
    // eslint-disable-next-line no-use-before-define
    const C = get(renderers, name)

    if (type === 'text') {
      const content = data.split('[nl]').join('\n')
      const parsedChildren = []

      // eslint-disable-next-line no-use-before-define
      MarkdownContent({
        content,
        renderers: get(C, 'renderers') || {},
      }).forEach(child => {
        if (child.type.name === 'HtmlBlock')
          // eslint-disable-next-line no-use-before-define
          renderers
            .HtmlBlock(child.props)
            .forEach(item => parsedChildren.push(item))
        else parsedChildren.push(child)
      })

      return parsedChildren
    } if (C)
      return (
        <C {...attribs} key={key}>
          {children.length > 1
            ? map(children, (ch, i) => transform(ch, i))
            : transform(children[0])}
        </C>
      )
  }

  const HtmlBlock = ({ literal }) =>
    ReactHtmlParser(literal, {
      lowerCaseTags: false,
      lowerCaseAttributeNames: false,
      transform,
    })

  const renderers = {
    heading: ({ level, children }) => (
      <Header importance={level}>{children}</Header>
    ),
    link: Link,
    ...extraRenderers,
    CodeBlock: HtmlBlock,
    HtmlBlock,
    HtmlInline: stuff => <span />,
    table: MyTable,
    tr: Table.Row,
    td: Table.Cell,
    th: Table.HeaderCell,
    thead: Table.Header,
    tbody: Table.Body,
  }

  return renderers
}

const MarkdownContent = ({
  content = '',
  renderers,
  parserOptions = {},
  renderOptions = {},
  walker,
}) => {
  const renderer = new ReactRenderer({
    ...renderOptions,
    renderers: getRenderers(renderers),
  })

  const parser = new Parser(parserOptions)

  const preparedContent = content.replace(
    /<([a-zA-Z]*)(.*)>([\s\S]*?)<\/\1>/g,
    (...args) => {
      const [ , tag, attr, body] = args
      return `<${tag}${attr}>${body
        .split(/\n.? ? ? ?\n/)
        .join('\n[nl]')}</${tag}>`
    },
  )

  const ast = parser.parse(preparedContent)

  if (walker) {
    const _walker = ast.walker()
    let event

    // eslint-disable-next-line no-cond-assign
    while ((event = _walker.next())) {
      walker.call(this, event, _walker)
    }
  }

  const renderedContent = renderer.render(ast)

  return renderedContent
}

export default MarkdownContent

import styled from 'styled-components'
import { cloneDeep, isPlainObject, set, get } from 'lodash'

const VarCollection = defaults => {
  const vars = cloneDeep(defaults)

  const setLocal = (pathOrObj, val) => {
    if (isPlainObject(pathOrObj)) Object.assign(vars, pathOrObj)
    else set(vars, pathOrObj, val)
  }

  const getLocal = path => get(vars, path)

  const getAll = () => cloneDeep(vars)

  return { set: setLocal, get: getLocal, getAll }
}

const spacing = VarCollection({
  tiny: '5px',
  small: '8px',
  medium: '15px',
  large: '30px',
  xlarge: '40px',
})

const Spacing = styled.div`
  ${({ size = 'medium' }) => `
    height: 0px;
    display: block;
    margin-top: ${spacing.get(size)}
  `}
`

export default Spacing

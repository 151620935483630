import { createClient } from 'contentful';

export const credentials = {
	// This is the space ID. A space is like a project folder in Contentful terms
	space: '4ecu34rp31r9',
	// This is the access token for this space. Normally you get both ID and the token in the Contentful web app
	accessToken: '17d8962cf8fbfe39901fbfbc4525fd47de0cf5ea994f4e01eb3e89e002ced155'
};

const client = createClient(credentials)

export default client;
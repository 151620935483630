import React, { Component } from 'react'
import JSS from '@oldscope/jss'
import colors from 'variables/colors'
import { oneOf } from 'prop-types'
import AnimatedEqualizer from '@oldscope/ui/components/AnimatedEqualizer'
import { debounce, inRange } from 'lodash'
import PlayerButton from '../components/MediaPlayer/PlayerButton'
import { withMediaProps } from '../components/MediaPlayer/custom_modules/react-media-player'
import AudioPlayer from '../components/MediaPlayer/AudioPlayer'

@withMediaProps
@JSS({
  equalizer: {
    zIndex: '190',
    background: `${colors.content.background.normal} !important`,
    position: 'fixed',
    right: '40px',
    bottom: '40px',
    borderColor: colors.primary,
    transition: 'opacity 0.5s',
  },

  common: {
    position: 'fixed',
    zIndex: '2000 !important',
    width: '300px',
    padding: '20px',
  },

  'window-player': {
    right: '20px',
    bottom: '20px',
    background: 'rgba(75,65,120,0.9)',
    borderRadius: '5px',
    opacity: 1,
    transition: 'width 0.5s, opacity 0.5s',
  },

  'feedBar-player': {
    right: '0px',
    bottom: '0px',
    background: colors.content.background.normal,
    transition: 'width 0.5s, opacity 0.5s',
  },

  hidePlayer: {
    opacity: 0,
    zIndex: '180 !important',
    width: '0px !important',
  },

  hideButton: {
    opacity: 0,
    zIndex: '180 !important',
  },
})
class PlayerBar extends Component {
  state = {
    expanded: false,
  }

  static propTypes = {
    context: oneOf(['window', 'feedBar']),
  }

  static defaultProps = {
    context: 'window',
  }

  showPlayer = () => {
    if (this.hidePlayer.cancel) {
      this.hidePlayer.cancel()
    }
    this.setState({ expanded: true })
  }

  hidePlayer = () => {
    if (this.state.expanded === true)
      this._cancelHide = setTimeout(() => {
        if (this._ready) {
          this.setState({ expanded: false })
        }
      }, 300)
  }

  isMouseInsidePlayer = debounce(e => {
    if (this._container) {
      const width = this._container.childNodes[0].offsetWidth + 20 * 2
      const x = [20 + width, 20].map(fromR => window.innerWidth - fromR)

      const height = this._container.childNodes[0].offsetHeight + 20 * 2
      const y = [20 + height, 20].map(fromR => window.innerHeight - fromR)

      if (!inRange(e.clientX, ...x) || !inRange(e.clientY, ...y)) {
        this.hidePlayer()
      } else {
        clearTimeout(this._cancelHide)
      }
    }
  }, 100)

  componentDidMount = () =>
    window.addEventListener('mousemove', this.isMouseInsidePlayer)

  componentWillUnmount = () =>
    window.removeEventListener('mousemove', this.isMouseInsidePlayer)

  componentDidUpdate = () => {
    this._ready = false
    setTimeout(() => (this._ready = true), 500)
  }

  render() {
    const { visible, src, handleClose, context, cx } = this.props
    const { expanded } = this.state

    return visible
      ? [
          context === 'window' && (
            <PlayerButton
              key="e"
              className={cx(expanded && 'hideButton', 'equalizer')}
              onMouseOver={this.showPlayer}
            >
              <AnimatedEqualizer barWidth={2} width={12} />
            </PlayerButton>
          ),

          <div
            ref={c => (this._container = c)}
            onMouseOut={
              context === 'window' && expanded ? /* this.hidePlayer */ null : null
            }
            onClick={e => e.stopPropagation()}
            key="p"
            className={cx(
              context === 'feedBar' || expanded || 'hidePlayer',
              'common',
              `${context}-player`,
            )}
          >
            <AudioPlayer
              {...{
                window: {
                  color1: '#fff',
                  color2: '#7E769D',
                  handleBG: colors.primary,
                },
                feedBar: {
                  color1: colors.primary,
                  color2: '#C8C6D3',
                  handleBG: colors.content.background.normal,
                },
              }[context]}
              handleClose={handleClose}
              src={src}
            />
          </div>,
        ]
      : null
  }
}

export default PlayerBar

import React from 'react'
import { object } from 'prop-types'
import HigherOrderComponent from '@oldscope/hoc'
import { cloneDeep } from 'lodash'

export default HigherOrderComponent((InnerComponent, Opts) => {
  return class ContainerSizeSubscriber extends React.Component {
    static contextTypes = {
      [`containerSizeProvider_${Opts.name}`]: object,
    }

    UNSAFE_componentWillMount() {
      this._localSubscriber = this.context[
        `containerSizeProvider_${Opts.name}`
      ].subscribe(this)
    }

    componentWillUnmount() {
      this._localSubscriber.unsubscribe()
    }

    render() {
      const dynamic = {
        [`${Opts.name}_getAdjustedConditionFn`]: this._localSubscriber
          .getAdjustedConditionFn,
        [`${Opts.name}_sizeCondition`]: this._localSubscriber.condition,
      }

      return (
        <InnerComponent
          {...this.props}
          sizeConditions={cloneDeep(this._localSubscriber.getConditions())}
          {...dynamic}
        />
      )
    }
  }
})

import React from 'react'
import StandardEventItem from '@oldscope/ui/components/Feed/items/EventItem'
import { format, subWeeks } from 'date-fns'
import Feed from './_ContentfulFeed'
import { ContextSubscriber } from '../LegacyContext'

const EventItem = props => (
  <ContextSubscriber subscriptions={{ globals: ['change'] }}>
    {({ globals: { change } }) => {
      const handleItemClick = () => {
        if (window.innerWidth < 800) change('feedBarVisible', false)
      }

      return <StandardEventItem {...props} handleItemClick={handleItemClick} />
    }}
  </ContextSubscriber>
)

const EventFeed = () => (
  <Feed
    urlPath="/event"
    ItemComponent={EventItem}
    extraFields={[
      'endDateTime',
      'location',
      'externalURL',
      'useExternalUrlAsNavLink',
    ]}
    extraQuery={{
      'startDateTime[gte]': format(subWeeks(new Date(), 1)),
    }}
    contentType="event"
    fields={{ image: 'featuredImage', date: 'startDateTime' }}
    placeholderIcon="calendar"
  />
)

export default EventFeed

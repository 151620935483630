import React from 'react'
import styled from 'styled-components'
import colors from '../variables/colors'
import { ContextSubscriber } from '../components/LegacyContext'
import BaseCloseButton from '../components/CloseButton'

const TitleBar = styled.div`
  flex: 0 0 auto;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 25px;
  background: #fff;
  font-size: 30px;
  color: ${colors.primary};
  font-family: 'Averia Serif Libre', sans-serif;
`
const Title = styled.div`
  margin-top: -2px;
`
const CloseButton = styled(BaseCloseButton)`
  height: 100%;
  margin-left: auto;
  padding: 0px 25px;
`

export default class SidebarTitlebar extends React.Component {
  render() {
    const { title, sidebarName } = this.props

    return (
      <ContextSubscriber subscriptions={{ globals: ['change'] }}>
        {({ globals: { change } }) => (
          <TitleBar>
            {title ? <Title>{title}</Title> : ''}
            <CloseButton
              onClick={() => change(`${sidebarName}Visible`, false)}
              background={colors.primary}
            />
          </TitleBar>
        )}
      </ContextSubscriber>
    )
  }
}

import React from 'react'
import JSS from '@oldscope/jss'

class PauseIconThin extends React.Component {
  static defaultProps = {
    color: '#000',
    size: '16px',
  }

  render() {
    return <div className={this.props.classes.pauseIcon} />
  }
}

export default JSS({
  pauseIcon: ({ _jss, size, color }) => ({
    height: size,
    width: `${(parseInt(size.replace('px', ''), 10) / 4) * 3}px`,
    borderLeft: `2px solid ${color}`,
    borderRight: `2px solid ${color}`,
  }),
})(PauseIconThin)

export default {
    primary: '#4b4178',
    secondary: '#756e9d',
    accent: '#ffc864',
    content: {
        background: {
            normal: '#dcdbe5',
            light: '#edecf1',
        }
    },
    button: {
        primary: '#f97910',
    },
};
import React from 'react'
import JSS from '@oldscope/jss'
import { spacing as spacingOpts } from '@oldscope/ui/_options'
import NavList from './NavList'

class SubList extends React.Component {
  render() {
    const { classes, items, orientation } = this.props

    return (
      <div className={classes.container}>
        <NavList {...{ items, orientation }} />
      </div>
    )
  }
}

export default JSS({
  container: {
    display: 'none',
    position: 'absolute',
    top: '100%',
    left: '0px',
    border: '1px solid #333',
    borderRadius: '5px',
    background: '#fff',
    padding: spacingOpts.medium,
  },
})(SubList)

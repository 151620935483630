import React from 'react'
import { omit, each, set, get, without, mapValues, pick } from 'lodash'
import { object, string, bool, func, any } from 'prop-types'
import HigherOrderComponent from './HigherOrderComponent'
import { eachDiffDeep } from '../util/ObjUtilsNew'
import equal from '../util/equal'

let nextContextSubscriberID = 1

const ContextSubscriberHOC = HigherOrderComponent(
  (InnerComponent, subListOrFn) =>
    class ConstructedContextSubscriber extends React.Component {
      render() {
        const {
          ensureFirstUpdate,
          ...subscriptions
        } = subListOrFn.getSubscriptions
          ? subListOrFn.getSubscriptions(this.props)
          : subListOrFn
        return (
          <ContextSubscriber
            ensureFirstUpdate={ensureFirstUpdate}
            debug={get(subListOrFn, 'debug')}
            subscriptions={subscriptions}
          >
            {context => {
              return <InnerComponent {...context} {...this.props} />
            }}
          </ContextSubscriber>
        )
      }
    },
)

class ContextSubscriber extends React.Component {
  static propTypes = {
    ensureFirstUpdate: bool,
    subscriptions: object,
    children: func,
  }

  _id = nextContextSubscriberID++

  constructor(props) {
    super(props)
    this.state = {
      numUpdates: 0,
      ensureFirstUpdate: props.ensureFirstUpdate,
    }
  }

  static contextTypes = {
    _subscriptionProvider: object,
  }

  updateSelf = changed => {
    if (changed || this.state.ensureFirstUpdate) {
      this._data = null
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        numUpdates: this.state.numUpdates + 1,
        ensureFirstUpdate: false,
      })
    }
  }

  subscriptionPathsOnly = () =>
    mapValues(this.props.subscriptions, subList =>
      subList.map(sub => (sub.indexOf(': ') > -1 ? sub.split(': ')[0] : sub)),
    )

  getSubscriptionProvider = () =>
    this.context._subscriptionProvider ||
    (console.error('Context not found'),
    {
      subscribe: () => {},
      unsubscribe: () => {},
      getData: () => ({}),
    })

  componentDidMount = () =>
    this.getSubscriptionProvider().subscribe(
      this.subscriptionPathsOnly(),
      this._id,
      this.updateSelf,
    )

  componentWillUnmount = () =>
    this.getSubscriptionProvider().unsubscribe(
      this.subscriptionPathsOnly(),
      this._id,
    )

  getDataFromContext = () => {
    if (!this._data) {
      const data = this.getSubscriptionProvider().getData()
      this._data = mapValues(this.props.subscriptions, (pathList, name) => {
        const obj = {}
        const src = data[name]

        pathList.forEach(path => {
          const parts = path.split(': ')
          if (parts[0] === '*') Object.assign(obj, src)
          else set(obj, parts[1] || parts[0], get(src, parts[0]))
        })

        return obj
      })
    }
    return this._data
  }

  render() {
    return this.props.children(this.getDataFromContext())
  }
}

class ContextProvider extends React.Component {
  static propTypes = {
    name: string.isRequired,
    context: any,
    children: any,
  }

  _subscriptions = {}

  _subscribers = {}

  static childContextTypes = {
    _subscriptionProvider: object,
  }

  static contextTypes = {
    _subscriptionProvider: object,
  }

  getData = () => ({
    ...(get(this.context._subscriptionProvider, 'getData') || (() => {}))(),
    [this.props.name]: this.props.context,
  })

  getChildContext = () => ({
    _subscriptionProvider: pick(this, ['subscribe', 'unsubscribe', 'getData']),
  })

  componentDidUpdate = prevProps => {
    const changed =
      prevProps !== this.props &&
      (!equal(prevProps.context, this.props.context) ||
        // eslint-disable-next-line eqeqeq
        prevProps.name != this.props.name)

    let isDiff = false
    const toUpdate = []

    const addToUpdate = path => {
      const subscriptions = get(this._subscriptions, `${path}._subscribers`)
      if (subscriptions && subscriptions.length > 0) {
        subscriptions.forEach(sub => {
          if (!toUpdate.includes(sub)) toUpdate.push(sub)
        })
      }
    }

    eachDiffDeep(prevProps.context, this.props.context, (newPath, nextVal) => {
      isDiff = true
      addToUpdate(newPath)
    })

    if (isDiff) addToUpdate('*')

    toUpdate.forEach(id => {
      const fn = this._subscribers[id]
      if (fn) fn(changed)
    })
  }

  subscribe = (subscriptions, id, fn) =>
    this.setSubscriptions(subscriptions, id, fn)

  unsubscribe = (subscriptions, id) =>
    this.setSubscriptions(subscriptions, id, null)

  setSubscriptions = (subscriptions, id, fn) => {
    const local = subscriptions[this.props.name]
    const passThrough = omit(subscriptions, this.props.name)

    if (this.context._subscriptionProvider)
      this.context._subscriptionProvider.subscribe(passThrough, id, fn)

    each(local, idx => {
      const existing = get(this._subscriptions, `${idx}._subscribers`) || []
      set(
        this._subscriptions,
        `${idx}._subscribers`,
        fn ? [...existing, id] : without(existing, id),
      )
      this._subscribers[id] = fn
    })
  }

  render() {
    return this.props.children
  }
}

export { ContextSubscriberHOC, ContextSubscriber, ContextProvider }

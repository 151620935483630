import React from 'react'
import Header from '@oldscope/ui/components/Header'
import { get } from 'lodash'
import { Container } from 'semantic-ui-react'
import 'semantic-ui-css/components/container.css'
import styled from 'styled-components'

const DefaultFullTextComponent = ({ children }) => <span>{children}</span>

const MyContainer = styled(Container)`
  padding-top: 40px;
  position: relative;
  @media (max-width: 999px) {
    padding-top: 30px;
  }
`

const Columns = styled.div`
  ${({ isSidebar }) => isSidebar && `display: flex;`}
  @media (max-width: 767px) {
    display: block;
  }
`

const Sidebar = styled.div`
  flex: 5 5 0px;
  padding-left: 32px;
  @media (max-width: 767px) {
    padding-left: 0px;
  }
`

const Body = styled.div`
  flex: 11 11 0px;
`

const Img = styled.img`
  width: 100%;
  margin-bottom: 32px;
`

export default ({
  hideTitle,
  title,
  children,
  image,
  sidebar,
  FullTextComponent = DefaultFullTextComponent,
}) => (
  <MyContainer key="content">
    {image && <Img src={`${image}?w=1200&h=250&fit=thumb`} />}
    <Columns isSidebar={!!sidebar}>
      <Body>
        {!hideTitle && <Header importance={1}>{title}</Header>}
        <FullTextComponent>{children}</FullTextComponent>
      </Body>
      {sidebar && (
        <Sidebar>
          <FullTextComponent>{get(sidebar, 'fields.body')}</FullTextComponent>
        </Sidebar>
      )}
    </Columns>
  </MyContainer>
)

import React from 'react'
import ContainerSizeSubscriber from '@oldscope/responsive/ContainerSizeSubscriber'
import NavList from '../../components/Nav/NavList'
import TopNavItem from './_TopNavItem'
import TopNavSubList from './_TopNavSubList'

@ContainerSizeSubscriber({ name: 'topnav' })
class TopNavList extends React.Component {
  render() {
    return (
      <NavList
        size={this.props.topnav_sizeCondition('w<1000') ? 'medium' : 'large'}
        ItemComponent={TopNavItem}
        SubListComponent={TopNavSubList}
        {...this.props}
      />
    )
  }
}

export default TopNavList

import React from 'react';
import { spacing } from '@oldscope/ui/_options';
import { arr_ensure } from '@oldscope/utils/array';
import { debounce } from 'lodash';

export default class DOMDisplacementIf extends React.Component {

    _prevHeight = 0;

    state = {
        contentHeight: 0,
    };

    componentDidMount = () => {
        this.checkContentHeight();
        this._interval = setInterval(this.checkContentHeight, 150);
    };

    componentWillUnmount = () => clearInterval(this._interval);

    checkContentHeight = debounce(() => {
        const contentHeight = this._container.nextSibling.offsetHeight;
        // eslint-disable-next-line eqeqeq
        if (contentHeight != this.state.contentHeight) {
            this.setState({ contentHeight });
        }
    });

    render () {
        const { is, children, additionalHeight, additionalHeightRatio } = this.props;

        let height = parseInt(this.state.contentHeight, 10);
        if (additionalHeight) height += parseInt((spacing[additionalHeight] || additionalHeight).replace('px', ''), 10);
        if (additionalHeightRatio) height *= additionalHeightRatio + 1;

        return [
            is && <div key='displacement' className='displacement' style={{ height }} />,
            <span key='id' className='displacementID' ref={c => this._container = c} />,
            ...arr_ensure(children),
        ]
    }

}
import React from 'react'
import { absoluteCenter } from '@oldscope/ui/_cssUtilities'
import styled from 'styled-components'

const Logo = styled.img`
  ${absoluteCenter('both')}
  @media (max-width: 999px) {
    width: 550px;
  }
  @media (max-width: 799px) {
    width: 450px;
  }
  @media (max-width: 599px) {
    width: 350px;
  }
  @media (max-width: 449px) {
    width: 250px;
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  background: url('https://images.contentful.com/4ecu34rp31r9/BrOfTumQ6GAmiYaegsWKk/a462ab08182fe680faf632a8c4aa8f47/header-bg.jpg')
    50% 50% no-repeat;
  background-size: 100% auto;
  margin-bottom: 40px;
  @media (max-width: 999px) {
    height: 350px;
  }
  @media (max-width: 799px) {
    height: 300px;
    margin-bottom: 20px;
  }
  @media (max-width: 599px) {
    height: 230px;
    margin-bottom: 15px;
  }
  @media (max-width: 449px) {
    height: 200px;
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 1500px) {
    background-position: 50% 40%;
  }
`

export default () => (
  <Container>
    <Logo
      alt="CPC Logo"
      src="https://images.contentful.com/4ecu34rp31r9/4vkYZDkjyEYSgCgeOss0qS/88b052d679b4cc06c10a15922a173e6c/cpc-logo_white.png"
    />
  </Container>
)

import React, { Component } from 'react'
import PlayerBar from './PlayerBar'
import { ContextSubscriber } from '../components/LegacyContext'

export default class PlayerBarContainer extends Component {
  render() {
    return (
      <ContextSubscriber
        subscriptions={{ globals: ['feedBarVisible', 'change', 'playerSrc'] }}
      >
        {({ globals: { feedBarVisible, change, playerSrc } }) => (
          <PlayerBar
            context={feedBarVisible ? 'feedBar' : 'window'}
            handleClose={() => change('playerSrc', null)}
            visible={!!playerSrc}
            src={playerSrc}
          />
        )}
      </ContextSubscriber>
    )
  }
}

import React from 'react'
import StandardFeedItem from '@oldscope/ui/components/Feed/items/StandardFeedItem'
import { mapKeys, get, mapValues, omit, compact } from 'lodash'
import { string, object, array, any } from 'prop-types'
import StandardPage from '@oldscope/ui/components/PageTemplates/StandardPage'
import MarkdownContent from '../MarkdownContent'
import { ContentfulQuery } from './index'

const FullTextComponent = props => <MarkdownContent {...props} />
const PageComponent = fields => (
  <StandardPage {...fields} FullTextComponent={FullTextComponent} />
)

const StandardPostUI = ({
  single,
  loaderType,
  invertLoader,
  contentType,
  extraQuery,
  extraFields,
  orderField,
  fields: _matchFields,
  placeholderIcon,
  ItemComponent: _ItemComponent,
  urlPath,
  which,
}) => {
  const ItemComponent =
    _ItemComponent || (single ? PageComponent : StandardFeedItem)

  const matchFields = {
    title: 'title',
    date: 'date',
    image: 'image',
    slug: 'slug',
    ...(single && {
      body: 'body',
      sidebar: 'sidebar',
    }),
    ..._matchFields,
  }

  const renderItem = function(fields, i = 0) {
    if (!fields) return null
    const convertedFields = {
      url: `${urlPath}/${fields[matchFields.slug]}`,
      ...omit(fields, Object.values(matchFields)),
      ...mapValues(matchFields, f => fields[f]),
    }
    if (convertedFields.image)
      convertedFields.image = get(convertedFields.image, 'fields.file.url')

    return (
      <ItemComponent
        key={i}
        placeholderIcon={placeholderIcon}
        {...convertedFields}
      />
    )
  }

  return (
    <ContentfulQuery
      loaderType={loaderType}
      single={single}
      invertLoader={invertLoader}
      query={{
        content_type: contentType,
        ...(single && { 'fields.slug': which }),
        ...mapKeys(extraQuery, (val, key) => `fields.${key}`),
        select: [...extraFields, ...compact(Object.values(matchFields))]
          .map(field => `fields.${field}`)
          .join(','),
        ...(!single && {
          order: `-fields.${matchFields[orderField] || orderField}`,
        }),
      }}
    >
      {single
        ? fields => renderItem(fields)
        : items =>
            items
              ? items.map(({ fields } = {}, i) => renderItem(fields, i))
              : []}
    </ContentfulQuery>
  )
}

StandardPostUI.propTypes = {
  contentType: string,
  extraQuery: object,
  extraFields: array,
  orderField: string,
  ItemComponent: any,
  urlPath: string,
}

StandardPostUI.defaultProps = {
  extraQuery: {},
  extraFields: [],
  orderField: 'date',
  // ItemComponent: StandardFeedItem,
  urlPath: '',
}

export default StandardPostUI

// Utilities
import React from 'react'
import withRouter from 'react-router-dom/withRouter'
import ContainerSizeProvider from '@oldscope/responsive/ContainerSizeProvider'
import styled from 'styled-components'
import { Left, Right } from '@oldscope/ui/components/Positioning'
import DPTopNavItems from './dataProviders/DPTopNavItems'
import { ContextSubscriber } from '../../components/LegacyContext'

// Nav parts
import TopBar from './_TopBar'
import TopNavList from './_TopNavList'
import TopNavItem from './_TopNavItem'
import Logo from './Logo'

// Other UI

const MyTopNavItem = styled(TopNavItem)`
  padding: 0px !important;
  &::before {
    background: none !important;
  }
  &:first-child > a {
    margin-left: -7px !important;
  }
  &:last-child > a {
    margin-right: -7px !important;
  }
`

const MyTopNavLinkedItem = styled(({ className, ...other }) => (
  <MyTopNavItem {...other} linkClassName={className} />
))`
  padding: 0px 7px !important;
`

const TopNavIcon = ({ first, ...props }) => (
  <MyTopNavLinkedItem {...props} hideLabel />
)

const SidebarButtons = styled(TopNavItem)`
  @media (max-width: 799px) {
    padding-left: 0px !important;
  }
`

const LeftList = styled(TopNavList)`
  text-transform: lowercase;
  & li:first-child {
    padding-left: 7px;
  }
`

@withRouter
@ContainerSizeProvider({
  name: 'topnav',
  sizeConditions: {
    isMobile: 'w<800',
    isSmallScreen: 'w<1000',
  },
})
class TopNav extends React.Component {
  state = {
  }

  render() {
    const { sizeCondition } = this.props
    const isMobile = sizeCondition('w<800')

    return (
      <TopBar>
        {barIsFixed => [
          <Logo key="logo" in={barIsFixed} />,
          isMobile ? null : (
            <Left key="l">
              <DPTopNavItems>
                {items => <LeftList items={items} />}
              </DPTopNavItems>
            </Left>
          ),
          <Right key="r">
            <ContextSubscriber
              subscriptions={{ globals: ['feedBarVisible', 'change'] }}
            >
              {({ globals: { feedBarVisible, change } }) => (
                <TopNavList>
                  <SidebarButtons
                    noLink
                    label={
                      <ul style={{ margin: 0, padding: 0, height: '100%' }}>
                        <TopNavIcon
                          first
                          icon="headphones"
                          label="Podcasts"
                          onClick={() =>
                            change({
                              feedBarVisible: true,
                              feedBarTab: 'podcasts',
                            })
                          }
                        />
                        <TopNavIcon
                          icon="newspaper-o"
                          label="News"
                          onClick={() =>
                            change({
                              feedBarVisible: true,
                              feedBarTab: 'news',
                            })
                          }
                        />
                        <TopNavIcon
                          icon="file text outline"
                          label="Articles"
                          onClick={() =>
                            change({
                              feedBarVisible: true,
                              feedBarTab: 'articles',
                            })
                          }
                        />
                        <TopNavIcon
                          icon="calendar"
                          label="Events"
                          onClick={() =>
                            change({
                              feedBarVisible: true,
                              feedBarTab: 'events',
                            })
                          }
                        />
                      </ul>
                    }
                  />
                  {isMobile && (
                    <TopNavItem
                      icon="bars"
                      onClick={() => change('hamburgerVisible', true)}
                    />
                  )}
                </TopNavList>
              )}
            </ContextSubscriber>
          </Right>,
        ]}
      </TopBar>
    )
  }
}

export default TopNav

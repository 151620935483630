import React from 'react'
import Router from 'react-router-dom/BrowserRouter'
import { ContentfulProvider } from './components/ContentfulDataProvider'
import cfClient from './ContentfulClient'
import App from './App'

const AppWithProviders = props => (
  <ContentfulProvider client={cfClient}>
    <Router>
      <App />
    </Router>
  </ContentfulProvider>
)

export default AppWithProviders

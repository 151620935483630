import React from 'react'
import styled from 'styled-components'
import BaseFeedItem from './BaseFeedItem'
import { FeedDate } from './_parts'

const PodcastItemMetaSheet = styled.div`
  width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PodcastItem = ({ date, artist, ...props }) => {
  return (
    <BaseFeedItem {...props}>
      <PodcastItemMetaSheet>
        <FeedDate date={date} /> / {artist}
      </PodcastItemMetaSheet>
    </BaseFeedItem>
  )
}

export default PodcastItem

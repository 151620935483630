import { get } from 'lodash';

// const cache = {};

export default (prefix) => ({

	onProcessSheet: (sheet) => {
		sheet.options.meta = prefix;
	},

	onCreateRule: (name, decl, options) => {
		// if (isFunction(decl))
		// onsole.log('onCreateRule', decl.style, {name, decl: cloneDeep(decl), options: cloneDeep(options)});

		// return parent ? cache.get(parent.rules.raw[name]) : null
	},

	onProcessRule: (rule) => {
		if (!prefix) prefix = get(rule, 'options.sheet.options.prefix');

		const { className } = rule;
		if (rule.className && (rule.className.indexOf(`${prefix}-`) !== 0)) {
			const newClassName = `${prefix}-${className}`;
			rule.className = newClassName;
			rule.selectorText = rule.selectorText.replace(className, newClassName);
			rule.selector = rule.selectorText;
		}
		// rule.selector = `@${prefix}-${rule.selector.substr(1)}`;
		// onsole.log('onProcessRule', rule, prefix, rule.selector);
	},

	onProcessStyle: (style, rule) => {
		// onsole.log('onProcessStyle', clone(style), clone(rule));
		return style;
	},

	/* onChangeValue: (value, prop) => {
		onsole.log('onChangeValue', value, prop);
		return value;
	}, */
});
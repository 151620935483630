import React from 'react'
import { LoaderOverlay } from '@oldscope/ui'

// getComponent is a function that returns a promise for a component
// It will not be called until the first mount
export default function asyncComponent(getComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props)
      this.state = { Component: AsyncComponent.Component }
    }

    UNSAFE_componentWillMount() {
      if (!this.state.Component) {
        getComponent().then(Component => {
          AsyncComponent.Component = Component.default
          this.setState({ Component: Component.default })
        })
      }
    }

    render() {
      const { Component } = this.state
      if (Component) {
        return <Component {...this.props} />
      }
      return <LoaderOverlay />
    }
  }

  AsyncComponent.Component = null

  return AsyncComponent
}

import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import 'semantic-ui-css/components/icon.css'
import JSS from '@oldscope/jss'
import cx from 'classnames'
import { withMediaProps } from './custom_modules/react-media-player'

class MuteUnmute extends Component {
  _handleMuteUnmute = () => {
    this.props.media.muteUnmute()
  }

  render() {
    const {
      media: { /* isMuted, */ volume },
      classes,
      className,
    } = this.props

    let iconName
    if (volume >= 0.5) iconName = 'volume up'
    else if (volume > 0) iconName = 'volume down'
    else iconName = 'volume off'

    return (
      <Icon
        className={cx(className, classes.icon)}
        name={iconName}
        onClick={this._handleMuteUnmute}
      />
    )
  }
}

export default withMediaProps(
  JSS({
    _important: true,
  })(MuteUnmute),
)

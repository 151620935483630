import React from 'react'
import JSS from '@oldscope/jss'
import cx from 'classnames'
import { string, number } from 'prop-types'
import { times } from 'lodash'

class AnimatedEqualizer extends React.Component {
  static propTypes = {
    barCount: number,
    barColor: string,
    barWidth: number,
    width: number,
    height: number,
  }

  static defaultProps = {
    barCount: 3,
    barColor: '#333333',
    barWidth: 4,
    width: 16,
    height: 16,
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes, barCount, barWidth, barColor, width, height } = this.props

    const gapSize = Math.round((width - barWidth * barCount) / (barCount - 1))
    console.log('gapSize', gapSize)

    let currentX = 0
    const yVals = [Math.round(height / 2), 0, Math.round(height / 4)]

    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${width}`}
      >
        <defs />
        <g id="icon-equalizer-anim" fill="#4A4A4A">
          {times(barCount, i => {
            const toReturn = (
              <rect
                key={i}
                className={cx(
                  classes.bar,
                  classes.barMod,
                  classes[`bar${i + 1}`],
                )}
                x={currentX}
                y={yVals[i]}
                width={barWidth}
                height={height - yVals[i]}
              />
            )
            currentX += barWidth + gapSize
            return toReturn
          })}
        </g>
      </svg>
    )
  }
}

export default JSS({
  '@global @keyframes eqicon': {
    '@global 0%': {
      // height: 20px,
      transform: 'scaleY(1)',
    },
    '@global 50%': {
      // height: 3px,
      transform: 'scaleY(.5)',
    },
    '@global 100%': {
      // height: 20px,
      transform: 'scaleY(1)',
    },
  },

  bar: {
    position: 'absolute',
    bottom: '0px',
    animationName: 'eqicon',
    animationFill: 'forwards',
    animationIterationCount: 'infinite',
  },

  barMod: ({ _jss, barColor, width, height }) => ({
    fill: barColor,
    transformOrigin: `${height / 2}px ${width}px`,
  }),

  bar1: {
    animationDuration: '0.5s',
  },

  bar2: {
    animationDuration: '1.3s',
  },

  bar3: {
    animationDuration: '0.7s',
  },
})(AnimatedEqualizer)

import React from 'react'
import StandardFeedItem from '@oldscope/ui/components/Feed/items/StandardFeedItem'
import { ContextSubscriber } from '../components/LegacyContext'

export default class FeedItem extends React.Component {
  render() {
    return (
      <ContextSubscriber subscriptions={{ globals: ['change'] }}>
        {({ globals: { change } }) => {
          const handleItemClick = () => {
            if (window.innerWidth < 800) change('feedBarVisible', false)
          }

          return (
            <StandardFeedItem
              {...this.props}
              handleItemClick={handleItemClick}
            />
          )
        }}
      </ContextSubscriber>
    )
  }
}

import React from 'react'
import ReactRouterLink from 'react-router-dom/Link'
import { domainIsHome } from '@oldscope/utils/url'

const stripLocalOrigin = (str = '') => str.replace(window.location.origin, '')

export default class Link extends React.Component {
  onClick = (...args) => {
    const { onClick } = this.props
    const href = this.getHref()
    if (!href && args[0].preventDefault) args[0].preventDefault()
    if (onClick) onClick(...args)
  }

  getHref = () => {
    if (this.props.href !== '#') return stripLocalOrigin(this.props.href)
  }

  render() {
    const { className, children, onClick } = this.props
    const href = this.getHref()

    if (href && domainIsHome(href)) {
      return (
        <ReactRouterLink {...{ onClick, className }} to={href}>
          {children}
        </ReactRouterLink>
      )
    } 
      return (
        <a
          className={className}
          href={href || '#'}
          onClick={this.onClick}
          {...(href && { target: '_blank' })}
        >
          {children}
        </a>
      )
    
  }
}

import React from 'react';
import { oneOf } from 'prop-types';
import { upperFirst } from 'lodash';
import { controls } from './custom_modules/react-media-player'

export default class TimeStamp extends React.Component {

    static propTypes = {
        type: oneOf(['currentTime', 'duration']),
    };
    
    render () {
        const { type } = this.props;
        const C = controls[upperFirst(type)];

        return <C />; 
    }

}
import React from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import HigherOrderComponent from '@oldscope/hoc'
import {
  omit,
  values,
  clone,
  each,
  some,
  pick,
  isPlainObject,
  includes,
  reject,
  isEqual,
  cloneDeep,
  debounce,
  lt,
  gt,
  eq,
  isNull,
  find,
} from 'lodash'

const ContainerSizeProvider = HigherOrderComponent((InnerComponent, Opts) => {
  if (!Opts) Opts = {}
  if (Array.isArray(Opts)) Opts = { sizeConditions: Opts }
  if (!Opts.name) Opts.name = InnerComponent.displayName || InnerComponent.name
  const names = {}

  if (isPlainObject(Opts.sizeConditions)) {
    each(Opts.sizeConditions, (c, n) => (names[c] = n))
    Opts.sizeConditions = values(Opts.sizeConditions)
  }

  class ContainerSizeSubscriberInternal {
    constructor(initialDimensions, subscriberComponent, handleUnsubscribe) {
      this.subscriberComponent = subscriberComponent
      this.handleUnsubscribe = handleUnsubscribe
      this.initConditions()
      this.initCache(initialDimensions)
    }

    initConditions() {
      this.conditions = { w: [], h: [], x: [], y: [] }
    }

    getConditions = () => omit(this.cache, 'providerDimensions')

    initCache(initialDimensions) {
      this.cache = {
        providerDimensions: clone(initialDimensions) || {
          w: null,
          h: null,
          x: null,
          y: null,
        },
        w: {},
        h: {},
        x: {},
        y: {},
        named: {},
      }
    }

    eachDimension(fn) {
      each(['w', 'h', 'x', 'y'], fn)
    }

    setDimensions(d) {
      const changed = some(d, (dVal, dName) => {
        // eslint-disable-next-line eqeqeq
        if (!(this.cache.providerDimensions[dName] == dVal)) {
          this.cache.providerDimensions[dName] = dVal
          return this.updateConditionResultsInDimension(dName, true)
        }
      })

      if (changed && this.subscriberComponent && !this.unsubscribed)
        this.subscriberComponent.forceUpdate()
    }

    doneWithConditions() {
      this.eachDimension(dName => {
        this.cache[dName] = pick(this.cache[dName], this.conditions[dName])
      })
    }

    // returns whether any of the conditions changed
    updateConditionResultsInDimension(dName) {
      return some(this.conditions[dName], c => {
        return this.updateConditionResult(c)
      })
    }

    parseCondition = c => ({
      str: c,
      dName: c.substring(0, 1),
      gtlt: c.substring(1, 2),
      num: parseInt(c.substring(2), 10),
    })

    // returns whether the condition changed
    updateConditionResult(c) {
      let changed = false
      if (!isPlainObject(c)) c = this.parseCondition(c)
      const fn = { '<': lt, '>': gt, '=': eq }[c.gtlt]

      const result = isNull(this.cache.providerDimensions[c.dName])
        ? false
        : fn(this.cache.providerDimensions[c.dName], c.num) || false

      // eslint-disable-next-line eqeqeq
      if (!(result == this.cache[c.dName][c.str])) changed = true
      this.cache[c.dName][c.str] = result
      if (names[c.str]) this.cache.named[names[c.str]] = result

      return changed
    }

    getAdjustedConditionFn = containerAdj => c =>
      this.condition(c, containerAdj)

    condition = (c, containerAdj) => {
      c = this.parseCondition(c)

      if (containerAdj) {
        const oldNum = c.num
        c.num -= containerAdj
        c.str = c.str.replace(oldNum, c.num)
      }

      if (!includes(this.conditions[c.dName], c.str))
        this.conditions[c.dName].push(c.str)
      if (!this.cache[c.dName][c.str]) this.updateConditionResult(c)

      return this.cache[c.dName][c.str]
    }

    unsubscribe() {
      this.unsubscribed = true
      this.handleUnsubscribe()
    }
  }

  // begin component
  /**
   * IMPORTANT: In addition to the props below, you can also use all the props available in the Input atom.
   */
  // eslint-disable-next-line no-shadow
  return class ContainerSizeProvider extends React.Component {
    static childContextTypes = {
      [`containerSizeProvider_${Opts.name}`]: PropTypes.object,
    }

    getChildContext() {
      return {
        [`containerSizeProvider_${Opts.name}`]: this,
      }
    }

    constructor(props) {
      super(props)
      this._subscribers = []
      this.state = { firstRender: true }
    }

    subscribe(subscriberComponent) {
      const subscriber = new ContainerSizeSubscriberInternal(
        this._dimensions,
        subscriberComponent,
        () => {
          this._subscribers = reject(this._subscribers, 'unsubscribed')
        },
      )
      this._subscribers.push(subscriber)
      return subscriber
    }

    getDimension(which, source) {
      if (source === 'window') return window[`inner${which}`]
      
        let {parentNode} = findDOMNode(this)
        let dim = 0

        while (parentNode && dim === 0) {
          dim = parentNode[`offset${which}`]
          parentNode = parentNode.parentNode
        }

        return dim
      
    }

    handleResize(e) {
      if (this._resizeCancelled) return

      const dimensions = {
        w: this.getDimension('Width'),
        h: this.getDimension('Height'),
        x: this.getDimension('Width', 'window'),
        y: this.getDimension('Height', 'window'),
      }

      if (!isEqual(this._dimensions, dimensions)) {
        this._dimensions = dimensions
        each(this._subscribers, s => {
          s.setDimensions(dimensions)
        })
      }

      const localSubscriberConditions = {
        ...pick(this._localSubscriber.cache, ['w', 'h', 'x', 'y']),
        ...this._localSubscriber.cache.named,
      }
      if (!isEqual(this.state.sizeConditions, localSubscriberConditions)) {
        const sizeConditions = cloneDeep(localSubscriberConditions)
        this.setState({ sizeConditions })
      }
    }

    componentDidMount() {
      this.setState({ firstRender: false })
      this._resizeFn = debounce(() => this.handleResize(), 100, {
        leading: false,
        trailing: true,
      })

      // call resize now
      this.handleResize()
      // call resize on window resize
      window.addEventListener('resize', this._resizeFn)
      // call resize every 250 milliseconds in case something changes elsewhere
      this._interval = setInterval(() => this._resizeFn(), 150)
    }

    componentDidUpdate(prevProps, prevState) {
      this._resizeFn()
    }

    UNSAFE_componentWillMount() {
      this._localSubscriber = this.subscribe(/* no component passed, because this will be handled differently */)
      each(Opts.sizeConditions, c => {
        this._localSubscriber.condition(c)
      })
    }

    componentWillUnmount() {
      this._localSubscriber.unsubscribe()
      this._resizeCancelled = true
      window.removeEventListener('resize', this._resizeFn)
      this._resizeFn.cancel()
      clearInterval(this._interval)
    }

    sizePicker = sizes =>
      find(
        sizes,
        (val, size) =>
          size === 'default' || this._localSubscriber.condition(size),
      )

    render() {
      const { firstRender, sizeConditions } = this.state

      return firstRender ? (
        <span />
      ) : (
        <InnerComponent
          ref={c => (this._innerComponent = c)}
          {...this.props}
          sizeConditions={sizeConditions}
          sizeCondition={this._localSubscriber.condition}
          getAdjustedConditionFn={this._localSubscriber.getAdjustedConditionFn}
          sizePicker={this.sizePicker}
        />
      )
    }
  }
})

export default ContainerSizeProvider

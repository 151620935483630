import React, { Component } from 'react'
import { Loader, Icon } from 'semantic-ui-react'
import 'semantic-ui-css/components/loader.css'
import 'semantic-ui-css/components/icon.css'
import JSS from '@oldscope/jss'
import cx from 'classnames'
import PauseIconThin from '@oldscope/ui/components/icons/PauseIconThin'
import { withMediaProps } from './custom_modules/react-media-player'
import PlayerButton from './PlayerButton'

class PlayPause extends Component {
  static defaultProps = {
    color: '#000',
  }

  _handlePlayPause = () => {
    this.props.media.playPause()
  }

  render() {
    const {
      media: { isPlaying, isLoading },
      classes,
      className,
      color,
    } = this.props

    return (
      <PlayerButton
        className={cx(classes.button, className)}
        style={{ borderColor: color }}
        onClick={this._handlePlayPause}
      >
        {isLoading ? (
          <Loader
            className={classes.loader}
            inline
            active
            inverted
            size="tiny"
          />
        ) : isPlaying ? (
          <PauseIconThin color={color} />
        ) : (
          <Icon className={classes.icon} name="angle right" />
        )}
      </PlayerButton>
    )
  }
}

export default withMediaProps(
  JSS({
    _important: true,

    button: {
      position: 'relative',
      float: 'left',
    },
    icon: ({ _jss, color }) => ({
      display: 'block !important',
      margin: '0 -1px 0 0 !important',
      color,
      fontSize: '30px !important',
    }),
    loader: {
      width: '23px',
      height: '23px',
      marginLeft: '-4px',
    },
  })(PlayPause),
)

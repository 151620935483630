import { arr_ensure } from '@oldscope/utils/array';
import { isFunction, trim, last } from 'lodash';

export default function HigherOrderComponent(getComponentFn) {

	const localGetComponentFn = (inner, opts) => {
		const hoc = getComponentFn(inner, opts);

		if (inner.expose) {
			let expose = arr_ensure(inner.expose);
			const hocExpose = arr_ensure(hoc.expose);
			hoc.expose = [].concat(hocExpose).concat(expose);

			expose = expose.reduce((acc, val) => {
				val = val.split(' ');
				const type = acc[val[0]] ? val[0] : 'instance';
				acc[type].push(val[1] || val[0]);
				return acc;
			}, { static: [], instance: [] });

			expose.static.forEach(name => {
				hoc[name] = inner[name];
			});

			const oldComponentDidMount = hoc.prototype.componentDidMount;
			hoc.prototype.componentDidMount = function () {
				if (this._inner && !this._inner._hocInnerExposed) {
					expose.instance.forEach(name => {
						const inInst = this._inner;
						const val = inInst[name];
						this[name] = isFunction(val) ? val.bind(inInst) : val;
					});
					this._inner._hocInnerExposed = true;
				}

				if (oldComponentDidMount) (oldComponentDidMount.bind(this))();
			}

			const oldComponentDidUpdate = hoc.prototype.componentDidUpdate;
			hoc.prototype.componentDidUpdate = function () {
				if (this._inner && !this._inner._hocInnerExposed) {
					expose.instance.forEach(name => {
						const inInst = this._inner;
						const val = inInst[name];
						this[name] = isFunction(val) ? val.bind(inInst) : val;
					});
					this._inner._hocInnerExposed = true;
				}

				if (oldComponentDidUpdate) (oldComponentDidUpdate.bind(this))();
			}
		}

		let innerName = inner.displayName || inner.name;
		if (innerName && (innerName.indexOf(')') > -1)) innerName = last(trim(innerName, ')').split('('));

		hoc.fullDisplayName = `${hoc.displayName || hoc.name}(${innerName})`;
		hoc.displayName = innerName;

		return hoc;
	};

	// will be called as request for either an instantiated or an uninstantiated component
	return function (a, b) {
		if (isFunction(a)) {
			return localGetComponentFn(a, b);
		} 
		return (inner) => localGetComponentFn(inner, a);
	};

}
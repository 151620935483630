// import PerfTesting from 'utils/PerfTesting';
import { assign, isFunction, size, isString } from 'lodash';
import { obj_mapValuesDeep } from '@oldscope/utils/obj';
import JSSNamedInstance from './JSSNamedInstance';

export default class JSSCachedSheets {

	constructor (name) {
		assign(this, {
			subscriberID: 0,
			cache: {},
			name,
			jss: JSSNamedInstance(name),
		});
	}

	getHandle (argStr, css, type) {
		this.subscriberID++;
		const { cache, name, jss, subscriberID } = this;

		if (!cache[argStr]) {																																					// PerfTesting.start(['generate.runcss', 'generate']);
			if (isFunction(css)) css = css();																																		// PerfTesting.stop('generate.runcss');
			if (css._important) css = obj_mapValuesDeep(css, val => isString(val) ? `${val} !important` : val);
																																													// PerfTesting.start('generate.attach');
			cache[argStr] = {
				subscribers: {},
				sheet: jss.createStyleSheet(css, { meta: `${name}${type}` }).attach(),
			};																																										// PerfTesting.stop(['generate.attach', 'generate']);
		}																																											// else PerfTesting.start('getfromcache');

		const { sheet, subscribers } = cache[argStr];

		subscribers[subscriberID] = true;																																			// PerfTesting.stop('getfromcache');

		return { argStr, sheet, subscriberID };
	}

	releaseHandle (argStr, subscriberID) {
		const { sheet, subscribers } = this.cache[argStr] || {};

		if (subscribers) {
			delete subscribers[subscriberID];
			if (size(subscribers) < 1) {
				sheet.detach();
				this.cache[argStr] = null;
			}
		}
	}

}
import React from 'react'
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadphones, faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt, faFileAlt } from '@fortawesome/free-regular-svg-icons'
import 'semantic-ui-css/components/menu.min.css'
import 'semantic-ui-css/components/icon.min.css'
import Sidebar from '@oldscope/ui/components/Sidebar'
import { map, capitalize } from 'lodash'
import withRouter from 'react-router-dom/withRouter'
import { ContextSubscriber } from '../components/LegacyContext'
import Feed from './_Feed'
import PodcastFeed from '../components/feeds/PodcastFeed'
import EventFeed from '../components/feeds/EventFeed'
import colors from '../variables/colors'
import SidebarTitlebar from './_SidebarTitlebar'

const PostFeed = ({ category, ...props }) => (
  <Feed
    contentType="post"
    extraFields={['category']}
    fields={{ image: 'featuredImage' }}
    urlPath="/post"
    extraQuery={{
      'category.sys.contentType.sys.id': '6XwpTaSiiI2Ak2Ww0oi6qa',
      'category.fields.title[match]': category,
    }}
    {...props}
  />
)

const tabs = {
  podcasts: {
    icon: faHeadphones,
    render: PodcastFeed,
  },
  news: {
    icon: faNewspaper,
    render: () => <PostFeed category="News" placeholderIcon="newspaper" />,
  },
  articles: {
    icon: faFileAlt,
    render: () => (
      <PostFeed category="Articles" placeholderIcon="file text outline" />
    ),
  },
  events: {
    icon: faCalendarAlt,
    render: EventFeed,
  },
}

const MyIcon = styled(FontAwesomeIcon)`
  display: block;
  margin: 0px auto 5px auto;
`

const TabsContainer = styled.div`
  flex: 0 0 auto;
  background: ${colors.content.background.normal} !important;
  display: flex !important;
  border: 0 !important;
`

const MySidebar = styled(Sidebar)`
  ${({ additional_css }) => additional_css}

  background: #fff;
  z-index: 1002 !important;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2) !important;
`

const SidebarInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const Tab = styled.div`
  position: relative;
  font-family: 'Averia Serif Libre', sans-serif;
  text-align: center !important;
  flex: 1 1 auto !important;
  color: ${colors.primary} !important;
  font-weight: bold !important;
  font-size: 15px !important;
  cursor: pointer;
  padding: 12px 0px;

  &:hover {
    background: ${colors.primary} !important;
    color: #fff !important;
    & i {
      color: ${colors.accent} !important;
    }
  }

  & i {
    float: none !important;
    display: block !important;
    font-size: 20px !important;
    margin: 0px auto 5px auto !important;
  }

  ${({ active }) =>
    active &&
    `
    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      background: ${colors.primary};
      bottom: 0px;
      left: 0px;
    }
  `}
`

const TabContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`

const MyMenu = styled(Menu)`
  width: 100% !important;
  overflow-x: hidden;
  height: 100% !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  font-family: inherit !important;
  & a {
    border-radius: 0 !important;
  }
`

@withRouter
class FeedBar extends React.Component {
  renderPostFeed = () => this.renderFeed('post', {})

  render() {
    return (
      <ContextSubscriber
        subscriptions={{
          globals: ['feedBarVisible', 'feedBarTab', 'playerSrc', 'change'],
        }}
      >
        {({ globals: { feedBarVisible, feedBarTab, playerSrc, change } }) => {
          if (!feedBarTab) feedBarTab = Object.keys(tabs)[0]
          return (
            <MySidebar
              animation="overlay"
              width="wide"
              direction="right"
              visible={feedBarVisible}
              additional_css={playerSrc ? 'padding-bottom: 100px;' : ''}
              handleClose={() =>
                feedBarVisible && change('feedBarVisible', false)
              }
            >
              <SidebarInner>
                <SidebarTitlebar
                  sidebarName="feedBar"
                  title={
                    <span>
                      <i>the</i>&nbsp;latest
                    </span>
                  }
                />
                <TabsContainer>
                  {map(tabs, ({ icon }, name) => (
                    <Tab
                      key={name}
                      active={feedBarTab === name}
                      onClick={() => change('feedBarTab', name)}
                    >
                      <MyIcon icon={icon} size="lg" />
                      {capitalize(name)}
                    </Tab>
                  ))}
                </TabsContainer>
                <TabContent>
                  <MyMenu width="wide" pointing vertical>
                    {tabs[feedBarTab].render()}
                  </MyMenu>
                </TabContent>
              </SidebarInner>
            </MySidebar>
          )
        }}
      </ContextSubscriber>
    )
  }
}

export default FeedBar

import React from 'react'
import styled from 'styled-components'
import Sidebar from '@oldscope/ui/components/Sidebar'
import withRouter from 'react-router-dom/withRouter'
import { Icon } from 'react-fa'
import { number } from 'prop-types'
import { map } from 'lodash'
import colors from '../variables/colors'
import { ContextSubscriber } from '../components/LegacyContext'
import SidebarTitlebar from './_SidebarTitlebar'
import DPTopNavItems from './top/dataProviders/DPTopNavItems'
import Link from '../components/Link'

const MyItem = styled.li`
  list-style: none;
  margin: 0px;
  border-top: 1px solid #dbd9e4;

  ${({ level }) =>
    level === 1 &&
    `
    &:last-child {
      border-bottom: 1px solid #dbd9e4;
    }
  `}
`

const TitleLink = styled(Link)`
  color: ${colors.primary};
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-transform: lowercase;
  text-decoration: none;

  ${({ level }) => `padding: 12px 20px 12px ${(level - 1) * 10 + 20}px;`},
`

const MyIcon = styled(Icon)`
  margin-left: auto;
  color: #b7bec9;
`

class ListItem extends React.Component {
  static propTypes = {
    level: number,
  }

  static defaultProps = {
    level: 1,
  }

  render() {
    const {
      children,
      title,
      level,
      itemClass,
      titleClass,
      href,
      onClick,
    } = this.props

    return (
      <MyItem className={itemClass} level={level}>
        <TitleLink className={titleClass} {...{ href, onClick }} level={level}>
          {title}
          <MyIcon name="angle-right" />
        </TitleLink>
        {children && <List>{children}</List>}
      </MyItem>
    )
  }
}

const SubItem = styled(({ className, ...other }) => (
  <ListItem {...other} level={2} titleClass={className} />
))`
  font-weight: 300;
  color: ${colors.secondary};
`

const List = styled.ul`
  margin: 0px;
  padding: 0px;
`

const MySidebar = styled(Sidebar)`
  background: #fff;
  z-index: 1001 !important;
`

const MenuContainer = styled.div`
  padding: 0px 20px;
`

const TheLatest = styled(List)`
  margin-bottom: 30px !important;
  background: ${colors.content.background.light};
  border-radius: 5px;
`

const TheLatestItem = styled(({ className, ...other }) => (
  <ListItem itemClass={className} {...other} />
))`
  border: 0 !important;
`

@withRouter
class Hamburger extends React.Component {
  renderList = (items, ItemComponent, changeFn) =>
    map(items, ({ subItems, slug, label, href, ...other }) => (
      <ItemComponent
        key={slug}
        title={label}
        href={href}
        onClick={() => changeFn(`hamburgerVisible`, false)}
      >
        {subItems ? this.renderList(subItems, SubItem, changeFn) : null}
      </ItemComponent>
    ))

  render() {
    return (
      <ContextSubscriber
        subscriptions={{ globals: ['hamburgerVisible', 'playerSrc', 'change'] }}
      >
        {({ globals: { hamburgerVisible, playerSrc, change } }) => (
          <MySidebar
            animation="overlay"
            width="wide"
            direction="right"
            visible={hamburgerVisible}
            handleClose={() =>
              hamburgerVisible && change('hamburgerVisible', false)
            }
          >
            <SidebarTitlebar sidebarName="hamburger" />
            <MenuContainer>
              <TheLatest>
                <TheLatestItem
                  title={
                    <span>
                      <i>the</i> Latest
                    </span>
                  }
                >
                  <SubItem
                    title={
                      <span>
                        <Icon name="headphones" /> Podcasts
                      </span>
                    }
                    onClick={() =>
                      change({
                        hamburgerVisible: false,
                        feedBarVisible: true,
                        feedBarTab: 'podcasts',
                      })
                    }
                  />
                  <SubItem
                    title={
                      <span>
                        <Icon name="newspaper-o" /> News
                      </span>
                    }
                    onClick={() =>
                      change({
                        hamburgerVisible: false,
                        feedBarVisible: true,
                        feedBarTab: 'news',
                      })
                    }
                  />
                  <SubItem
                    title={
                      <span>
                        <Icon name="file text outline" /> Articles
                      </span>
                    }
                    onClick={() =>
                      change({
                        hamburgerVisible: false,
                        feedBarVisible: true,
                        feedBarTab: 'articles',
                      })
                    }
                  />
                  <SubItem
                    title={
                      <span>
                        <Icon name="calendar" /> Events
                      </span>
                    }
                    onClick={() =>
                      change({
                        hamburgerVisible: false,
                        feedBarVisible: true,
                        feedBarTab: 'events',
                      })
                    }
                  />
                </TheLatestItem>
              </TheLatest>
              <DPTopNavItems>
                {items => this.renderList(items, ListItem, change)}
              </DPTopNavItems>
            </MenuContainer>
          </MySidebar>
        )}
      </ContextSubscriber>
    )
  }
}

export default Hamburger

import React from 'react'
import styled from 'styled-components'
import { bool, oneOfType, string, number, any, object } from 'prop-types'
import DocumentTitle from 'react-document-title'
import { compact, isUndefined, isNull, values, omitBy } from 'lodash'

const MyHeader = styled.h1`
  font-weight: bold;
  display: block;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 0;
  line-height: 1.28571429em;
  text-transform: none;
  border: none;
  &:first-child {
    margin-top: '0px';
  }
  &:last-child: {
    margin-bottom: '0px';
  }

  margin: ${({ noMargin }) =>
    noMargin ? '0px' : 'calc(2rem - .14285714em) 0 1rem'};
  font-size: ${({ importance }) =>
    ({
      1: '2.25rem',
      2: '1.75rem',
      3: '1.43rem',
      4: '1.25rem',
      5: '1.09rem',
      6: '.9rem',
    }[importance])};
`

export default class Heading extends React.PureComponent {
  static propTypes = {
    /**
     * Should this set the browser title?
     */
    pageTitle: bool,
    /**
     * Level of importance (e.g. 1 for h1, 2 for h2, etc.)
     */
    importance: oneOfType([string, number]),
    /**
     * Extra class name to apply
     */
    className: string,
    /**
     * Text of heading
     */
    children: any,
    noMargin: bool,
    classes: object,
  }

  static defaultProps = {
    pageTitle: false,
  }

  static contextTypes = {
    parentTitle: string,
  }

  browserTitle() {
    const titles = compact([this.props.children, this.context.parentTitle])

    return values(omitBy(titles, t => isUndefined(t) || isNull(t))).join(' | ')
  }

  render() {
    const { pageTitle, className, children, importance, noMargin } = this.props

    return (
      <>
        {pageTitle && (
          <DocumentTitle key="browserTitle" title={this.browserTitle()} />
        )}
        <MyHeader
          {...{ importance, noMargin }}
          as={`h${importance}`}
          key="domHeader"
          className={className}
        >
          {children}
        </MyHeader>
      </>
    )
  }
}

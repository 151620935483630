import {
  isPlainObject
} from 'lodash'

const uniqKeys = (...objs) => {
  const result = []
  objs.forEach(
    obj =>
      obj &&
      Object.keys(obj).forEach(key => {
        if (!result.includes(key)) result.push(key)
      }),
  )
  return result
}

/*
 * Takes 2 full sets of data, compares them, and runs a function for every difference
 */
export const eachDiffDeep = (
  a,
  b,
  diffFn,
  { sameFn, onlyIncludeSpecified = false } = {},
) => {
  const examineObject = (_a, _b, path) => {
    const keys = onlyIncludeSpecified ? Object.keys(_b) : uniqKeys(_a, _b)
    keys.forEach(key => {
      const _aVal = _a && _a[key]
      const _bVal = _b && _b[key]
      const newPath = `${path ? `${path}.` : ''}${key}`
      // eslint-disable-next-line no-prototype-builtins
      const isInChanges = _b && _b.hasOwnProperty && _b.hasOwnProperty(key)

      if (_aVal !== _bVal) {
        diffFn(newPath, _bVal, isInChanges)

        if (isPlainObject(_aVal) || isPlainObject(_bVal)) {
          examineObject(_aVal || {}, _bVal || {}, newPath)
        }
      } else if (sameFn) sameFn(newPath, _bVal, isInChanges)
    })
  }
  examineObject(a, b)
}

import React from 'react'
import { Sidebar as SUISidebar } from 'semantic-ui-react'
import 'semantic-ui-css/components/sidebar.css'
import styled from 'styled-components'

class OutsideClickHandler extends React.Component {
  componentDidMount = () => {
    setTimeout(() => window.addEventListener('click', this.handleClose), 0)
  }

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClose)
  }

  handleClose = e => {
    const { handleClose } = this.props

    const sidebar =
      this._locator.nextSibling || this._locator.nextElementSibling
    if (handleClose && !sidebar.contains(e.target)) handleClose()
  }

  render() {
    return <span ref={c => (this._locator = c)} />
  }
}

class Sidebar extends React.Component {
  render() {
    const { className, children, handleClose, ...props } = this.props
    return (
      <>
        {this.props.visible && (
          <OutsideClickHandler handleClose={handleClose} />
        )}
        <SUISidebar {...props} className={className}>
          {children}
        </SUISidebar>
      </>
    )
  }
}

const StyledSidebar = styled(Sidebar)`
  width: 300px !important;
`

export default StyledSidebar
